export const Colors = [
  "hsl(220deg, 90%, 80%)", //blue
  "hsl(125deg, 90%, 80%)", //green
  "hsl(40deg, 90%, 80%)", //yellow
  "hsl(175deg, 90%, 80%)", //cyan
  "hsl(250deg, 90%, 80%)", //purple
  "hsl(357deg, 90%, 80%)", //pink
  "hsl(23deg, 90%, 80%)", //orange
  "hsl(80deg, 80%, 80%)", //green2
]
