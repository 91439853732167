import { Plus } from "@phosphor-icons/react"
import { Button } from "@vesatogo/grass-core"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "~/constants/routes"

export const AddBenchmark = () => {
  const navigate = useNavigate()
  return (
    <Button
      size="sm"
      leftIcon={<Plus />}
      onClick={() => navigate(`${AppRoutes.benchmark}/new`)}
    >
      Benchmark
    </Button>
  )
}
