import { useForm } from "@mantine/form"
import { ArrowLeft } from "@phosphor-icons/react"
import {
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  FormGroup,
  FormInput,
  FormQuerySelector,
  Modal,
  ModuleContent,
  ModuleHeader,
  SpinnerOverlay,
} from "@vesatogo/grass-core"
import { FileUpload } from "@vesatogo/grass-dropzone"
import { cloneDeep, set } from "lodash-es"
import { useEffect, useMemo, useRef, useState } from "react"
import { toast } from "react-hot-toast"
import { Link, useNavigate, useParams } from "react-router-dom"
import {
  useAuthorization,
  useIsSuperAdmin,
} from "~/constants/AuthorizationProvider"
import { Environment } from "~/constants/Environment"
import { Permission } from "~/constants/Permissions"
import { AppRoutes } from "~/constants/routes"
import {
  useAddUpdateBaseUserMutation,
  useAllPermissionsQuery,
  useBaseUserDetailsQuery,
} from "~/generated/graphql"

const INITIAL_STATE = {
  id: null,
  username: "",
  first_name: "",
  last_name: "",
  email: "",
  is_dashboard_admin: false,
  permissions: [],
  photo: [
    {
      url: "",
      media_kind: "",
    },
  ],
  password: "",
  confirm_password: "",
}
export type InitialState = typeof INITIAL_STATE
export const UserDetails = () => {
  const { id } = useParams()
  const [submitted, setSubmitted] = useState(false)
  const canCreateUser = useAuthorization(Permission.CanCreateNewUser)
  const canChangePassword = useAuthorization(Permission.CanChangePassword)

  const formRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const isSuperAdmin = useIsSuperAdmin()
  const navigate = useNavigate()

  const isAuthorizedCreateUser = useMemo(
    () => (isSuperAdmin ? true : canCreateUser ? true : false),
    [isSuperAdmin]
  )
  const isAuthorizedChangePassword = useMemo(
    () => (isSuperAdmin ? true : canChangePassword ? true : false),
    [isSuperAdmin]
  )
  const form = useForm<InitialState>({
    initialValues: INITIAL_STATE,
  })
  const { values: state, setValues: setState } = form
  const [{ data, fetching }, refetch] = useBaseUserDetailsQuery({
    variables: {
      id: id || "",
    },
  })

  const [{ fetching: upsertFetching }, addUpdateUser] =
    useAddUpdateBaseUserMutation()

  useEffect(() => {
    const user = data?.base_user_detail
    setState({
      username: user?.username,
      email: user?.email,
      first_name: user?.first_name,
      last_name: user?.last_name,
      id: user?.id as any,
      is_dashboard_admin: user?.is_dashboard_admin || false,
      permissions: user?.permissions as any,
      photo: user?.photo ? [user?.photo] : ([] as any),
    })
  }, [data])
  async function handleSubmit(onComplete?: () => void) {
    setSubmitted(true)
    if (
      !state?.email ||
      !state?.first_name ||
      !state.last_name ||
      !state?.username
    ) {
      return toast.error("Please enter mandatory details!")
    }
    const { data, error } = await addUpdateUser({
      input: {
        email: state?.email,
        first_name: state?.first_name,
        last_name: state?.last_name,
        id: state?.id,
        is_dashboard_admin: state?.is_dashboard_admin,
        permissions: state?.permissions?.map((permission: any) => {
          return permission?.id
        }),
        photo: {
          url: state?.photo?.[0]?.url,
          media_kind: state?.photo[0]?.media_kind,
        },
        username: state?.username,
      },
    })
    if (error) {
      return toast.error("Something went wrong!")
    }
    setState({
      email: undefined,
      id: "new" as any,
      first_name: undefined,
      is_dashboard_admin: false,
      last_name: undefined,
      permissions: [],
      photo: [],
      username: undefined,
    })
    refetch({ requestPolicy: "network-only" })
    toast.success("Saved successfully")
    onComplete?.()
  }

  const onSubmit = async e => {
    e.preventDefault()
    if (state?.password !== state?.confirm_password)
      return toast.error("Please resolve all issues before proceeding.")
    setLoading(true)

    const { error } = await addUpdateUser({
      input: {
        id: id !== "new" ? id : undefined,
        password: state?.password,
        email: state?.email,
        first_name: state?.first_name,
        last_name: state?.last_name,
        is_dashboard_admin: state?.is_dashboard_admin,
        permissions: state?.permissions?.map((permission: any) => {
          return permission?.id
        }),
        photo: {
          url: state?.photo?.[0]?.url,
          media_kind: state?.photo[0]?.media_kind,
        },
        username: state?.username,
      },
    })

    if (error) {
      toast.error(error?.message)
      setLoading(false)
      return
    }

    setLoading(false)
    setState({
      ...state,
      password: "",
      confirm_password: "",
    })
    onClose()
    toast.success("Password changed successfully!")
  }

  const resetPassword = () => {
    setState({
      ...state,
      password: "",
      confirm_password: "",
    })
  }

  const onClose = () => {
    resetPassword()
    setOpen(false)
  }
  return (
    <>
      <ModuleHeader
        first={
          <div className="flex !items-end">
            <ArrowLeft
              className="w-6 mr-2 cursor-pointer "
              onClick={() => history.back()}
            />
            <Breadcrumbs
              items={[
                {
                  text: "Home",
                  link: `${AppRoutes?.userManagement}?updatedAt=${Date.now()}`,
                  isActive: false,
                },
                {
                  text: state?.username || "New",
                  link: `${AppRoutes.userManagement}/${id}`,
                  isActive: true,
                },
              ]}
              linkRenderer={(link, item) => {
                return <Link to={link as any}>{item}</Link>
              }}
            />
          </div>
        }
      />
      <ModuleContent className="p-4">
        <SpinnerOverlay show={fetching || upsertFetching}></SpinnerOverlay>
        <Card className=" h-[80vh] flex flex-col gap-4">
          <div className="p-4  flex flex-col gap-4">
            <div className="grid grid-cols-5 gap-4 ">
              <FormInput
                required
                label="Username"
                value={state?.username}
                onChange={e => {
                  setState({
                    ...state,
                    username: e,
                  })
                }}
              ></FormInput>
              <FormGroup
                disabled={!isAuthorizedChangePassword}
                labelProps={{
                  className: "text-white",
                }}
                label="Password"
              >
                <Button
                  disabled={!isAuthorizedChangePassword}
                  className=""
                  onClick={() => setOpen(true)}
                >
                  Set Password
                </Button>
                <Modal
                  overflow={true}
                  bodyClassName={"px-4 py-2 max-w-lg"}
                  primaryActionButtonProps={{
                    text: "Save",
                    onClick: onSubmit,
                    loading: loading,
                  }}
                  secondaryActionButtonProps={{
                    text: "Close",
                    onClick: () => {
                      resetPassword()
                      setOpen(false)
                    },
                  }}
                  title="Set Password"
                  isOpen={open}
                  onClose={() => {
                    resetPassword()
                    setOpen(false)
                  }}
                >
                  <form>
                    <div className="flex flex-col justify-between gap-4">
                      <FormInput
                        type="password"
                        value={state?.password}
                        onChange={e => setState({ ...state, password: e })}
                        label="Password"
                      ></FormInput>
                      <FormInput
                        type="password"
                        error={
                          state?.confirm_password !== state?.password
                            ? "The passwords do not match."
                            : ""
                        }
                        value={state?.confirm_password}
                        onChange={e =>
                          setState({ ...state, confirm_password: e })
                        }
                        label="Confirm Password"
                      ></FormInput>
                    </div>
                    <Button ref={formRef} className="hidden" type="submit">
                      Submit
                    </Button>
                  </form>
                </Modal>
              </FormGroup>
              <FormInput
                required
                label="First Name"
                value={state?.first_name}
                onChange={e => {
                  setState({
                    ...state,
                    first_name: e,
                  })
                }}
              ></FormInput>
              <FormInput
                required
                label="Last Name"
                value={state?.last_name}
                onChange={e => {
                  setState({
                    ...state,
                    last_name: e,
                  })
                }}
              ></FormInput>
              <FormInput
                required
                label="Email"
                value={state?.email}
                onChange={e => {
                  setState({
                    ...state,
                    email: e,
                  })
                }}
              ></FormInput>
            </div>
            <div className="grid grid-cols-5 gap-4">
              <FormQuerySelector
                label="Permissions"
                className={"col-span-3"}
                value={state?.permissions}
                dataHook={useAllPermissionsQuery}
                isMulti
                onChange={e => {
                  setState({
                    ...state,
                    permissions: e,
                  })
                }}
              ></FormQuerySelector>
              <FormGroup label="Photo">
                <FileUpload
                  onError={toast.error}
                  key={JSON.stringify(state)}
                  uploadCardClassName="w-32  h-32 "
                  className=" w-full h-full max-h-fit mx-auto"
                  media={state.photo || []}
                  endpoint={Environment.FileUploadHost}
                  environment={""}
                  maxFiles={1}
                  appName="moepl/test"
                  onChange={async media => {
                    const stateCopy = cloneDeep(state)
                    set(stateCopy, "photo", media)
                    setState(stateCopy)
                    return
                  }}
                />
              </FormGroup>
              <FormGroup
                label="Is Dashboard Admin"
                labelProps={{ className: "text-white" }}
              >
                <Checkbox
                  label={"Is Super Admin"}
                  checked={state?.is_dashboard_admin}
                  disabled={!isSuperAdmin}
                  onChange={e =>
                    setState({
                      ...state,
                      is_dashboard_admin: e,
                    })
                  }
                ></Checkbox>
              </FormGroup>
            </div>
          </div>
          <div className="flex col-span-4 bg-gray-200 gap-4 justify-end px-8 py-2">
            <Button
              disabled={!isAuthorizedCreateUser}
              onClick={() => {
                handleSubmit(() => {
                  navigate(`${AppRoutes.userManagement}/new`)
                })
              }}
              variant="ghost"
            >
              Save and add new
            </Button>
            <Button
              disabled={!isAuthorizedCreateUser}
              onClick={() => {
                handleSubmit(() => {
                  refetch({ requestPolicy: "network-only" })
                  navigate(
                    `${AppRoutes.userManagement}?updatedAt=${Date.now()}`
                  )
                })
              }}
            >
              Save
            </Button>
          </div>
        </Card>
      </ModuleContent>
    </>
  )
}

export default UserDetails
