import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Decimal: any;
  GenericScalar: any;
};

export type BaseUserFilterInput = {
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type BaseUserInput = {
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<MediaInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type BaseUserType = {
  __typename?: 'BaseUserType';
  benchmarks: Array<BenchmarkType>;
  created_at: Scalars['DateTime'];
  created_by?: Maybe<BaseUserType>;
  date_joined: Scalars['DateTime'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['ID'];
  is_dashboard_admin: Scalars['Boolean'];
  last_login?: Maybe<Scalars['DateTime']>;
  last_name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<PermissionType>>>;
  photo?: Maybe<MediaType>;
  rb_core_baseuser_created: Array<BaseUserType>;
  rb_core_baseuser_updated: Array<BaseUserType>;
  updated_at: Scalars['DateTime'];
  updated_by?: Maybe<BaseUserType>;
  /** Specific permissions for this user. */
  user_permissions: Array<PermissionType>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
};

export type BenchmarkDetails = {
  __typename?: 'BenchmarkDetails';
  factory?: Maybe<FactoryType>;
  summary?: Maybe<BenchmarkFactorySummaryType>;
};

export type BenchmarkFactorySummaryInput = {
  commodity_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  date__gte?: InputMaybe<Scalars['Date']>;
  date__lte?: InputMaybe<Scalars['Date']>;
  factory_id?: InputMaybe<Scalars['ID']>;
};

export type BenchmarkFactorySummaryType = {
  __typename?: 'BenchmarkFactorySummaryType';
  benchmarks: Array<BenchmarkType>;
  commodity_grade: CommodityGradeType;
  created_at: Scalars['DateTime'];
  date?: Maybe<Scalars['Date']>;
  factory: FactoryType;
  id: Scalars['ID'];
  last_closing_rate?: Maybe<Scalars['Decimal']>;
  last_closing_rate_time?: Maybe<Scalars['DateTime']>;
  last_closing_rate_unit?: Maybe<UnitType>;
  latest_rate?: Maybe<Scalars['Decimal']>;
  latest_rate_time?: Maybe<Scalars['DateTime']>;
  latest_rate_unit?: Maybe<UnitType>;
  opening_rate?: Maybe<Scalars['Decimal']>;
  opening_rate_time?: Maybe<Scalars['DateTime']>;
  opening_rate_unit?: Maybe<UnitType>;
  pin_index?: Maybe<Scalars['Int']>;
  updated_at: Scalars['DateTime'];
};

export type BenchmarkGraphGroupInput = {
  commodity_grade_id?: InputMaybe<Scalars['ID']>;
  factory_group_id?: InputMaybe<Scalars['ID']>;
  price_type?: InputMaybe<Scalars['String']>;
};

export type BenchmarkGraphInput = {
  commodity_grade_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  date?: InputMaybe<Scalars['Date']>;
  factory_group_inputs?: InputMaybe<Array<InputMaybe<BenchmarkGraphGroupInput>>>;
  unit_id?: InputMaybe<Scalars['ID']>;
};

export type BenchmarkInput = {
  commodity_grade_id?: InputMaybe<Scalars['ID']>;
  factory_id?: InputMaybe<Scalars['ID']>;
  /** Valid only for recorded_at, summary__factory__name */
  order_by?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  recorded_at__gte?: InputMaybe<Scalars['Date']>;
  recorded_at__lte?: InputMaybe<Scalars['Date']>;
  summary_id?: InputMaybe<Scalars['ID']>;
};

export type BenchmarkType = {
  __typename?: 'BenchmarkType';
  attachments: Array<MediaType>;
  commodity_grade: CommodityGradeType;
  factory: FactoryType;
  from_rate?: Maybe<Scalars['Decimal']>;
  id: Scalars['ID'];
  no_of_days?: Maybe<Scalars['Int']>;
  not_buying: Scalars['Boolean'];
  rate_unit?: Maybe<UnitType>;
  recorded_at: Scalars['DateTime'];
  recorded_by?: Maybe<BaseUserType>;
  remark?: Maybe<Scalars['String']>;
  summary: BenchmarkFactorySummaryType;
  to_rate?: Maybe<Scalars['Decimal']>;
};

export type BenchmarksByCommodity = {
  __typename?: 'BenchmarksByCommodity';
  commodity?: Maybe<CommodityType>;
  factories?: Maybe<Array<Maybe<FactoryBenchmarkGrade>>>;
};

export type BenchmarksByFactory = {
  __typename?: 'BenchmarksByFactory';
  closing_summary?: Maybe<BenchmarkFactorySummaryType>;
  factory?: Maybe<FactoryType>;
  latest_summary?: Maybe<BenchmarkFactorySummaryType>;
  opening_summary?: Maybe<BenchmarkFactorySummaryType>;
  pin_index?: Maybe<Scalars['Int']>;
};

export type BenchmarksByFactoryGroup = {
  __typename?: 'BenchmarksByFactoryGroup';
  benchmarks?: Maybe<Array<Maybe<BenchmarkFactorySummaryType>>>;
  factory_group?: Maybe<FactoryGroupType>;
};

export type BenchmarksByFactoryGroupInput = {
  commodity_grade_id__in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  date?: InputMaybe<Scalars['Date']>;
  factory_group_id?: InputMaybe<Scalars['ID']>;
};

export type BenchmarksByFactoryInput = {
  commodity_grade_id?: InputMaybe<Scalars['ID']>;
  date__gte?: InputMaybe<Scalars['Date']>;
  date__lte?: InputMaybe<Scalars['Date']>;
};

export type ChangePassword = {
  __typename?: 'ChangePassword';
  user?: Maybe<BaseUserType>;
};

export type CommodityGradeInput = {
  commodity_id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CommodityGradeType = {
  __typename?: 'CommodityGradeType';
  benchmark_factory_summaries: Array<BenchmarkFactorySummaryType>;
  benchmarks: Array<BenchmarkType>;
  commodity: CommodityType;
  id: Scalars['ID'];
  name: Scalars['String'];
  specifications?: Maybe<Scalars['String']>;
};

export type CommodityInput = {
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CommodityType = {
  __typename?: 'CommodityType';
  codename: Scalars['String'];
  grades: Array<CommodityGradeType>;
  id: Scalars['ID'];
  is_active: Scalars['Boolean'];
  name: Scalars['String'];
  photo?: Maybe<MediaType>;
};

export type FactoryBenchmarkGrade = {
  __typename?: 'FactoryBenchmarkGrade';
  date?: Maybe<Scalars['Date']>;
  factory?: Maybe<FactoryType>;
  grades?: Maybe<Array<Maybe<GradeBenchmarkData>>>;
};

export type FactoryFilters = {
  commodity_grade_id?: InputMaybe<Scalars['ID']>;
  recorded_at__date?: InputMaybe<Scalars['Date']>;
};

export type FactoryGroupBenchmarkType = {
  __typename?: 'FactoryGroupBenchmarkType';
  benchmark_details?: Maybe<Array<Maybe<BenchmarkDetails>>>;
  group?: Maybe<FactoryGroupType>;
};

export type FactoryGroupInput = {
  factory_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
};

export type FactoryGroupMembershipType = {
  __typename?: 'FactoryGroupMembershipType';
  factory: FactoryType;
  factory_group: FactoryGroupType;
  id: Scalars['ID'];
  sequence: Scalars['Int'];
};

export type FactoryGroupType = {
  __typename?: 'FactoryGroupType';
  factories: Array<FactoryType>;
  id: Scalars['ID'];
  members: Array<FactoryGroupMembershipType>;
  name: Scalars['String'];
};

export type FactoryInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type FactoryOrderInput = {
  factory_id?: InputMaybe<Scalars['ID']>;
  sequence?: InputMaybe<Scalars['Int']>;
};

export type FactoryType = {
  __typename?: 'FactoryType';
  benchmark_summaries: Array<BenchmarkFactorySummaryType>;
  benchmarks: Array<Maybe<BenchmarkType>>;
  factory_groups: Array<FactoryGroupMembershipType>;
  groups: Array<FactoryGroupType>;
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type FactoryTypeBenchmarksArgs = {
  filters?: InputMaybe<FactoryFilters>;
};

export type GradeBenchmarkData = {
  __typename?: 'GradeBenchmarkData';
  benchmarks?: Maybe<Array<Maybe<BenchmarkType>>>;
  grade?: Maybe<CommodityGradeType>;
};

export type Login = {
  __typename?: 'Login';
  me?: Maybe<BaseUserType>;
  payload: Scalars['GenericScalar'];
  refresh_expires_in: Scalars['Int'];
  refresh_token: Scalars['String'];
  token: Scalars['String'];
};

export type MediaInput = {
  id?: InputMaybe<Scalars['ID']>;
  media_kind?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type MediaType = {
  __typename?: 'MediaType';
  baseuser_set: Array<BaseUserType>;
  benchmark_set: Array<BenchmarkType>;
  commodity_set: Array<CommodityType>;
  id: Scalars['ID'];
  media_kind: RbCoreMediaMediaKindChoices;
  url: Scalars['String'];
};

export type MutationRoot = {
  __typename?: 'MutationRoot';
  change_password?: Maybe<ChangePassword>;
  login?: Maybe<Login>;
  pin_factory?: Maybe<PinFactory>;
  pin_summary?: Maybe<PinSummary>;
  refresh_token?: Maybe<Refresh>;
  update_profile?: Maybe<UpdateProfile>;
  upsert_base_user?: Maybe<UpsertBaseUserMutation>;
  upsert_benchmark?: Maybe<UpsertBenchmarkMutation>;
  upsert_factory?: Maybe<UpsertFactoryMutation>;
  upsert_factory_group?: Maybe<UpsertFactoryGroup>;
};


export type MutationRootChange_PasswordArgs = {
  new_password: Scalars['String'];
  old_password: Scalars['String'];
};


export type MutationRootLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationRootPin_FactoryArgs = {
  factory_id: Scalars['ID'];
};


export type MutationRootPin_SummaryArgs = {
  summary_id: Scalars['ID'];
};


export type MutationRootRefresh_TokenArgs = {
  refresh_token?: InputMaybe<Scalars['String']>;
};


export type MutationRootUpdate_ProfileArgs = {
  inputs: BaseUserInput;
};


export type MutationRootUpsert_Base_UserArgs = {
  inputs: UpsertBaseUserInput;
};


export type MutationRootUpsert_BenchmarkArgs = {
  inputs: UpsertBenchmarkInput;
};


export type MutationRootUpsert_FactoryArgs = {
  inputs: UpsertFactoryInput;
};


export type MutationRootUpsert_Factory_GroupArgs = {
  inputs: UpsertFactoryInput;
};

export type PermissionFilterInput = {
  codename?: InputMaybe<Scalars['String']>;
};

export type PermissionType = {
  __typename?: 'PermissionType';
  codename: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  /** Specific permissions for this user. */
  user_set: Array<BaseUserType>;
};

export type PinFactory = {
  __typename?: 'PinFactory';
  factory?: Maybe<FactoryType>;
};

export type PinSummary = {
  __typename?: 'PinSummary';
  summary?: Maybe<BenchmarkFactorySummaryType>;
};

export type QueryRoot = {
  __typename?: 'QueryRoot';
  all_base_users?: Maybe<Array<Maybe<BaseUserType>>>;
  all_benchmark_factory_summarys?: Maybe<Array<Maybe<BenchmarkFactorySummaryType>>>;
  all_benchmarks?: Maybe<Array<Maybe<BenchmarkType>>>;
  all_commodity_grades?: Maybe<Array<Maybe<CommodityGradeType>>>;
  all_commoditys?: Maybe<Array<Maybe<CommodityType>>>;
  all_factory_groups?: Maybe<Array<Maybe<FactoryGroupType>>>;
  all_factorys?: Maybe<Array<Maybe<FactoryType>>>;
  all_permissions?: Maybe<Array<Maybe<PermissionType>>>;
  all_units?: Maybe<Array<Maybe<UnitType>>>;
  base_user_detail?: Maybe<BaseUserType>;
  benchmark_detail?: Maybe<BenchmarkType>;
  benchmark_factory_summary_detail?: Maybe<BenchmarkFactorySummaryType>;
  benchmark_graph?: Maybe<Array<Maybe<FactoryGroupBenchmarkType>>>;
  benchmarks_by_commodity?: Maybe<Array<Maybe<BenchmarksByCommodity>>>;
  benchmarks_by_factory?: Maybe<Array<Maybe<BenchmarksByFactory>>>;
  benchmarks_by_factory_count?: Maybe<Scalars['Int']>;
  benchmarks_by_factory_groups?: Maybe<Array<Maybe<BenchmarksByFactoryGroup>>>;
  commodity_detail?: Maybe<CommodityType>;
  commodity_grade_detail?: Maybe<CommodityGradeType>;
  factory_detail?: Maybe<FactoryType>;
  factory_group_detail?: Maybe<FactoryGroupType>;
  me?: Maybe<BaseUserType>;
  permission_detail?: Maybe<PermissionType>;
  unit_detail?: Maybe<UnitType>;
};


export type QueryRootAll_Base_UsersArgs = {
  filter?: InputMaybe<BaseUserFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryRootAll_Benchmark_Factory_SummarysArgs = {
  filter?: InputMaybe<BenchmarkFactorySummaryInput>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryRootAll_BenchmarksArgs = {
  filter?: InputMaybe<BenchmarkInput>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryRootAll_Commodity_GradesArgs = {
  filter?: InputMaybe<CommodityGradeInput>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryRootAll_CommoditysArgs = {
  filter?: InputMaybe<CommodityInput>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryRootAll_Factory_GroupsArgs = {
  filter?: InputMaybe<FactoryGroupInput>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryRootAll_FactorysArgs = {
  filter?: InputMaybe<FactoryInput>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryRootAll_PermissionsArgs = {
  filter?: InputMaybe<PermissionFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryRootAll_UnitsArgs = {
  filter?: InputMaybe<UnitFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryRootBase_User_DetailArgs = {
  id: Scalars['ID'];
};


export type QueryRootBenchmark_DetailArgs = {
  id: Scalars['ID'];
};


export type QueryRootBenchmark_Factory_Summary_DetailArgs = {
  id: Scalars['ID'];
};


export type QueryRootBenchmark_GraphArgs = {
  filter: BenchmarkGraphInput;
};


export type QueryRootBenchmarks_By_CommodityArgs = {
  from_date?: InputMaybe<Scalars['Date']>;
  query?: InputMaybe<Scalars['String']>;
  to_date?: InputMaybe<Scalars['Date']>;
};


export type QueryRootBenchmarks_By_FactoryArgs = {
  filter?: InputMaybe<BenchmarksByFactoryInput>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryRootBenchmarks_By_Factory_CountArgs = {
  filter?: InputMaybe<BenchmarksByFactoryInput>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryRootBenchmarks_By_Factory_GroupsArgs = {
  factory_group_query?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BenchmarksByFactoryGroupInput>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryRootCommodity_DetailArgs = {
  id: Scalars['ID'];
};


export type QueryRootCommodity_Grade_DetailArgs = {
  id: Scalars['ID'];
};


export type QueryRootFactory_DetailArgs = {
  id: Scalars['ID'];
};


export type QueryRootFactory_Group_DetailArgs = {
  id: Scalars['ID'];
};


export type QueryRootPermission_DetailArgs = {
  id: Scalars['ID'];
};


export type QueryRootUnit_DetailArgs = {
  id: Scalars['ID'];
};

/** An enumeration. */
export enum RbCoreMediaMediaKindChoices {
  /** Archive */
  Archive = 'ARCHIVE',
  /** File */
  File = 'FILE',
  /** Image */
  Image = 'IMAGE',
  /** Video */
  Video = 'VIDEO'
}

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refresh_expires_in: Scalars['Int'];
  refresh_token: Scalars['String'];
  token: Scalars['String'];
};

export type UnitFilterInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type UnitType = {
  __typename?: 'UnitType';
  benchmark_set: Array<BenchmarkType>;
  codename: Scalars['String'];
  id: Scalars['ID'];
  last_closing_rate_benchmark_summaries: Array<BenchmarkFactorySummaryType>;
  latest_rate_benchmark_summaries: Array<BenchmarkFactorySummaryType>;
  name: Scalars['String'];
  opening_rate_benchmark_summaries: Array<BenchmarkFactorySummaryType>;
};

export type UpdateProfile = {
  __typename?: 'UpdateProfile';
  user?: Maybe<BaseUserType>;
};

export type UpsertBaseUserInput = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  is_dashboard_admin?: InputMaybe<Scalars['Boolean']>;
  last_name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  photo?: InputMaybe<MediaInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UpsertBaseUserMutation = {
  __typename?: 'UpsertBaseUserMutation';
  user?: Maybe<BaseUserType>;
};

export type UpsertBenchmarkInput = {
  attachments?: InputMaybe<Array<InputMaybe<MediaInput>>>;
  commodity_grade_id?: InputMaybe<Scalars['ID']>;
  factory_id?: InputMaybe<Scalars['ID']>;
  from_rate?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  no_of_days?: InputMaybe<Scalars['Int']>;
  not_buying?: InputMaybe<Scalars['Boolean']>;
  rate_unit_id?: InputMaybe<Scalars['ID']>;
  recorded_at?: InputMaybe<Scalars['DateTime']>;
  remark?: InputMaybe<Scalars['String']>;
  to_rate?: InputMaybe<Scalars['Decimal']>;
};

export type UpsertBenchmarkMutation = {
  __typename?: 'UpsertBenchmarkMutation';
  benchmark?: Maybe<BenchmarkType>;
};

export type UpsertFactoryGroup = {
  __typename?: 'UpsertFactoryGroup';
  factory_group?: Maybe<FactoryGroupType>;
};

export type UpsertFactoryInput = {
  factories?: InputMaybe<Array<InputMaybe<FactoryOrderInput>>>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpsertFactoryMutation = {
  __typename?: 'UpsertFactoryMutation';
  factory?: Maybe<FactoryType>;
};

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'MutationRoot', login?: { __typename?: 'Login', token: string, me?: { __typename?: 'BaseUserType', id: string, username: string, is_dashboard_admin: boolean, permissions?: Array<{ __typename?: 'PermissionType', id: string, name: string, codename: string } | null> | null } | null } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'QueryRoot', me?: { __typename?: 'BaseUserType', id: string, username: string, first_name: string, last_name: string, last_login?: any | null, email: string, is_dashboard_admin: boolean, permissions?: Array<{ __typename?: 'PermissionType', id: string, name: string, codename: string } | null> | null, photo?: { __typename?: 'MediaType', id: string, url: string } | null } | null };

export type AllUnitsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
}>;


export type AllUnitsQuery = { __typename?: 'QueryRoot', all_units?: Array<{ __typename?: 'UnitType', id: string, name: string } | null> | null };

export type AllFactoriesQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
}>;


export type AllFactoriesQuery = { __typename?: 'QueryRoot', all_factorys?: Array<{ __typename?: 'FactoryType', id: string, name: string } | null> | null };

export type AllCommoditiesQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
}>;


export type AllCommoditiesQuery = { __typename?: 'QueryRoot', all_commoditys?: Array<{ __typename?: 'CommodityType', id: string, name: string, is_active: boolean } | null> | null };

export type AllCommodityGradesQueryVariables = Exact<{
  commodity_id?: InputMaybe<Scalars['ID']>;
}>;


export type AllCommodityGradesQuery = { __typename?: 'QueryRoot', all_commodity_grades?: Array<{ __typename?: 'CommodityGradeType', id: string, name: string, specifications?: string | null } | null> | null };

export type AllBenchmarkFactorySummariesQueryVariables = Exact<{
  commodity_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  date_lte?: InputMaybe<Scalars['Date']>;
  date_gte?: InputMaybe<Scalars['Date']>;
  name?: InputMaybe<Scalars['String']>;
}>;


export type AllBenchmarkFactorySummariesQuery = { __typename?: 'QueryRoot', all_benchmark_factory_summarys?: Array<{ __typename?: 'BenchmarkFactorySummaryType', id: string, date?: any | null, opening_rate?: any | null, latest_rate?: any | null, opening_rate_time?: any | null, latest_rate_time?: any | null, pin_index?: number | null, opening_rate_unit?: { __typename?: 'UnitType', id: string, name: string } | null, latest_rate_unit?: { __typename?: 'UnitType', id: string, name: string } | null, factory: { __typename?: 'FactoryType', id: string, name: string }, commodity_grade: { __typename?: 'CommodityGradeType', id: string, name: string } } | null> | null };

export type AllBenchmarksQueryVariables = Exact<{
  filter?: InputMaybe<BenchmarkInput>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
}>;


export type AllBenchmarksQuery = { __typename?: 'QueryRoot', all_benchmarks?: Array<{ __typename?: 'BenchmarkType', id: string, recorded_at: any, from_rate?: any | null, to_rate?: any | null, no_of_days?: number | null, remark?: string | null, recorded_by?: { __typename?: 'BaseUserType', id: string, username: string, first_name: string, last_name: string, last_login?: any | null, photo?: { __typename?: 'MediaType', id: string, url: string } | null } | null, commodity_grade: { __typename?: 'CommodityGradeType', id: string, name: string, specifications?: string | null, commodity: { __typename?: 'CommodityType', id: string, name: string } }, factory: { __typename?: 'FactoryType', id: string, name: string }, rate_unit?: { __typename?: 'UnitType', id: string, name: string } | null, attachments: Array<{ __typename?: 'MediaType', id: string, url: string, media_kind: RbCoreMediaMediaKindChoices }> } | null> | null };

export type AllFactoryGroupsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
}>;


export type AllFactoryGroupsQuery = { __typename?: 'QueryRoot', all_factory_groups?: Array<{ __typename?: 'FactoryGroupType', id: string, name: string, members: Array<{ __typename?: 'FactoryGroupMembershipType', sequence: number, factory: { __typename?: 'FactoryType', id: string, name: string } }>, factories: Array<{ __typename?: 'FactoryType', id: string, name: string }> } | null> | null };

export type BenchmarkGraphQueryQueryVariables = Exact<{
  filter: BenchmarksByFactoryGroupInput;
}>;


export type BenchmarkGraphQueryQuery = { __typename?: 'QueryRoot', benchmarks_by_factory_groups?: Array<{ __typename?: 'BenchmarksByFactoryGroup', factory_group?: { __typename?: 'FactoryGroupType', name: string, id: string } | null, benchmarks?: Array<{ __typename?: 'BenchmarkFactorySummaryType', id: string, opening_rate?: any | null, latest_rate?: any | null, last_closing_rate?: any | null, last_closing_rate_time?: any | null, opening_rate_time?: any | null, updated_at: any, factory: { __typename?: 'FactoryType', id: string, name: string }, commodity_grade: { __typename?: 'CommodityGradeType', id: string, name: string, commodity: { __typename?: 'CommodityType', id: string, name: string } } } | null> | null } | null> | null };

export type UpsertFactoryGroupMutationVariables = Exact<{
  input: UpsertFactoryInput;
}>;


export type UpsertFactoryGroupMutation = { __typename?: 'MutationRoot', upsert_factory_group?: { __typename?: 'UpsertFactoryGroup', factory_group?: { __typename?: 'FactoryGroupType', id: string, name: string, factories: Array<{ __typename?: 'FactoryType', id: string }> } | null } | null };

export type AddUpdateBenchmarkMutationVariables = Exact<{
  inputs: UpsertBenchmarkInput;
}>;


export type AddUpdateBenchmarkMutation = { __typename?: 'MutationRoot', upsert_benchmark?: { __typename?: 'UpsertBenchmarkMutation', benchmark?: { __typename?: 'BenchmarkType', id: string, not_buying: boolean, from_rate?: any | null, to_rate?: any | null, recorded_at: any, remark?: string | null, no_of_days?: number | null, commodity_grade: { __typename?: 'CommodityGradeType', id: string, name: string, commodity: { __typename?: 'CommodityType', id: string, name: string } }, factory: { __typename?: 'FactoryType', id: string, name: string }, summary: { __typename?: 'BenchmarkFactorySummaryType', id: string, opening_rate?: any | null, latest_rate?: any | null, last_closing_rate?: any | null, last_closing_rate_time?: any | null, opening_rate_time?: any | null, latest_rate_time?: any | null, pin_index?: number | null, date?: any | null, updated_at: any }, recorded_by?: { __typename?: 'BaseUserType', id: string, username: string } | null, rate_unit?: { __typename?: 'UnitType', id: string, name: string } | null, attachments: Array<{ __typename?: 'MediaType', id: string, media_kind: RbCoreMediaMediaKindChoices, url: string }> } | null } | null };

export type PinIndexMutationVariables = Exact<{
  summary_id: Scalars['ID'];
}>;


export type PinIndexMutation = { __typename?: 'MutationRoot', pin_summary?: { __typename?: 'PinSummary', summary?: { __typename?: 'BenchmarkFactorySummaryType', id: string, pin_index?: number | null } | null } | null };

export type BenchmarkDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BenchmarkDetailQuery = { __typename?: 'QueryRoot', benchmark_detail?: { __typename?: 'BenchmarkType', id: string, recorded_at: any, from_rate?: any | null, not_buying: boolean, to_rate?: any | null, no_of_days?: number | null, remark?: string | null, commodity_grade: { __typename?: 'CommodityGradeType', id: string, name: string, specifications?: string | null, commodity: { __typename?: 'CommodityType', id: string, name: string } }, factory: { __typename?: 'FactoryType', id: string, name: string }, rate_unit?: { __typename?: 'UnitType', id: string, name: string } | null, attachments: Array<{ __typename?: 'MediaType', id: string, url: string, media_kind: RbCoreMediaMediaKindChoices }> } | null };

export type FactoryGroupDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FactoryGroupDetailsQuery = { __typename?: 'QueryRoot', factory_group_detail?: { __typename?: 'FactoryGroupType', id: string, name: string, members: Array<{ __typename?: 'FactoryGroupMembershipType', id: string, sequence: number, factory: { __typename?: 'FactoryType', id: string, name: string } }> } | null };

export type AddUpdateFactoryMutationVariables = Exact<{
  inputs: UpsertFactoryInput;
}>;


export type AddUpdateFactoryMutation = { __typename?: 'MutationRoot', upsert_factory?: { __typename?: 'UpsertFactoryMutation', factory?: { __typename?: 'FactoryType', id: string } | null } | null };

export type FactoryDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FactoryDetailsQuery = { __typename?: 'QueryRoot', factory_detail?: { __typename?: 'FactoryType', id: string, name: string } | null };

export type BenchmarksByFactoryQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BenchmarksByFactoryInput>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type BenchmarksByFactoryQuery = { __typename?: 'QueryRoot', benchmarks_by_factory?: Array<{ __typename?: 'BenchmarksByFactory', pin_index?: number | null, factory?: { __typename?: 'FactoryType', id: string, name: string } | null, opening_summary?: { __typename?: 'BenchmarkFactorySummaryType', id: string, opening_rate?: any | null, opening_rate_time?: any | null, opening_rate_unit?: { __typename?: 'UnitType', id: string, name: string } | null } | null, latest_summary?: { __typename?: 'BenchmarkFactorySummaryType', id: string, latest_rate?: any | null, latest_rate_time?: any | null, latest_rate_unit?: { __typename?: 'UnitType', id: string, name: string } | null } | null, closing_summary?: { __typename?: 'BenchmarkFactorySummaryType', id: string, latest_rate?: any | null, latest_rate_time?: any | null, latest_rate_unit?: { __typename?: 'UnitType', id: string, name: string } | null } | null } | null> | null };

export type PinFactoryMutationVariables = Exact<{
  factory_id: Scalars['ID'];
}>;


export type PinFactoryMutation = { __typename?: 'MutationRoot', pin_factory?: { __typename?: 'PinFactory', factory?: { __typename?: 'FactoryType', id: string } | null } | null };

export type BenchmarksByFactoryCountQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<BenchmarksByFactoryInput>;
}>;


export type BenchmarksByFactoryCountQuery = { __typename?: 'QueryRoot', benchmarks_by_factory_count?: number | null };

export type AllBaseUsersQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type AllBaseUsersQuery = { __typename?: 'QueryRoot', all_base_users?: Array<{ __typename?: 'BaseUserType', id: string, last_login?: any | null, username: string, first_name: string, last_name: string, email: string, is_dashboard_admin: boolean, date_joined: any, created_at: any, permissions?: Array<{ __typename?: 'PermissionType', id: string, name: string, codename: string } | null> | null, photo?: { __typename?: 'MediaType', id: string, url: string } | null, created_by?: { __typename?: 'BaseUserType', id: string, username: string } | null, updated_by?: { __typename?: 'BaseUserType', id: string, username: string } | null } | null> | null };

export type AllBaseUsersCountQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type AllBaseUsersCountQuery = { __typename?: 'QueryRoot', all_base_users?: Array<{ __typename?: 'BaseUserType', id: string } | null> | null };

export type AddUpdateBaseUserMutationVariables = Exact<{
  input: UpsertBaseUserInput;
}>;


export type AddUpdateBaseUserMutation = { __typename?: 'MutationRoot', upsert_base_user?: { __typename?: 'UpsertBaseUserMutation', user?: { __typename?: 'BaseUserType', id: string, is_dashboard_admin: boolean, first_name: string, last_name: string, permissions?: Array<{ __typename?: 'PermissionType', id: string, name: string, codename: string } | null> | null, photo?: { __typename?: 'MediaType', id: string, url: string, media_kind: RbCoreMediaMediaKindChoices } | null } | null } | null };

export type AllPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllPermissionsQuery = { __typename?: 'QueryRoot', all_permissions?: Array<{ __typename?: 'PermissionType', id: string, name: string, codename: string } | null> | null };

export type BaseUserDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BaseUserDetailsQuery = { __typename?: 'QueryRoot', base_user_detail?: { __typename?: 'BaseUserType', id: string, first_name: string, last_name: string, username: string, email: string, is_dashboard_admin: boolean, permissions?: Array<{ __typename?: 'PermissionType', id: string, name: string, codename: string } | null> | null, photo?: { __typename?: 'MediaType', id: string, url: string } | null } | null };


export const LoginDocument = gql`
    mutation login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    token
    me {
      id
      username
      permissions {
        id
        name
        codename
      }
      is_dashboard_admin
    }
  }
}
    `;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
};
export const MeDocument = gql`
    query Me {
  me {
    permissions {
      id
      name
      codename
    }
    id
    username
    first_name
    last_name
    last_login
    photo {
      id
      url
    }
    email
    is_dashboard_admin
  }
}
    `;

export function useMeQuery(options?: Omit<Urql.UseQueryArgs<MeQueryVariables>, 'query'>) {
  return Urql.useQuery<MeQuery, MeQueryVariables>({ query: MeDocument, ...options });
};
export const AllUnitsDocument = gql`
    query AllUnits($query: String) {
  all_units(query: $query) {
    id
    name
  }
}
    `;

export function useAllUnitsQuery(options?: Omit<Urql.UseQueryArgs<AllUnitsQueryVariables>, 'query'>) {
  return Urql.useQuery<AllUnitsQuery, AllUnitsQueryVariables>({ query: AllUnitsDocument, ...options });
};
export const AllFactoriesDocument = gql`
    query AllFactories($query: String) {
  all_factorys(query: $query) {
    id
    name
  }
}
    `;

export function useAllFactoriesQuery(options?: Omit<Urql.UseQueryArgs<AllFactoriesQueryVariables>, 'query'>) {
  return Urql.useQuery<AllFactoriesQuery, AllFactoriesQueryVariables>({ query: AllFactoriesDocument, ...options });
};
export const AllCommoditiesDocument = gql`
    query AllCommodities($query: String, $is_active: Boolean) {
  all_commoditys(query: $query, filter: {is_active: $is_active}) {
    id
    name
    is_active
  }
}
    `;

export function useAllCommoditiesQuery(options?: Omit<Urql.UseQueryArgs<AllCommoditiesQueryVariables>, 'query'>) {
  return Urql.useQuery<AllCommoditiesQuery, AllCommoditiesQueryVariables>({ query: AllCommoditiesDocument, ...options });
};
export const AllCommodityGradesDocument = gql`
    query AllCommodityGrades($commodity_id: ID) {
  all_commodity_grades(filter: {commodity_id: $commodity_id}) {
    id
    name
    specifications
  }
}
    `;

export function useAllCommodityGradesQuery(options?: Omit<Urql.UseQueryArgs<AllCommodityGradesQueryVariables>, 'query'>) {
  return Urql.useQuery<AllCommodityGradesQuery, AllCommodityGradesQueryVariables>({ query: AllCommodityGradesDocument, ...options });
};
export const AllBenchmarkFactorySummariesDocument = gql`
    query AllBenchmarkFactorySummaries($commodity_ids: [ID], $date_lte: Date, $date_gte: Date, $name: String) {
  all_benchmark_factory_summarys(
    query: $name
    filter: {commodity_ids: $commodity_ids, date__lte: $date_lte, date__gte: $date_gte}
  ) {
    id
    date
    opening_rate
    latest_rate
    opening_rate_time
    latest_rate_time
    pin_index
    opening_rate_unit {
      id
      name
    }
    latest_rate_unit {
      id
      name
    }
    factory {
      id
      name
    }
    commodity_grade {
      id
      name
    }
  }
}
    `;

export function useAllBenchmarkFactorySummariesQuery(options?: Omit<Urql.UseQueryArgs<AllBenchmarkFactorySummariesQueryVariables>, 'query'>) {
  return Urql.useQuery<AllBenchmarkFactorySummariesQuery, AllBenchmarkFactorySummariesQueryVariables>({ query: AllBenchmarkFactorySummariesDocument, ...options });
};
export const AllBenchmarksDocument = gql`
    query AllBenchmarks($filter: BenchmarkInput, $first: Int, $skip: Int, $query: String) {
  all_benchmarks(filter: $filter, query: $query, first: $first, skip: $skip) {
    id
    recorded_at
    recorded_by {
      id
      username
      first_name
      last_name
      photo {
        id
        url
      }
      last_login
    }
    from_rate
    to_rate
    commodity_grade {
      id
      name
      specifications
      commodity {
        id
        name
      }
    }
    factory {
      id
      name
    }
    no_of_days
    remark
    rate_unit {
      id
      name
    }
    attachments {
      id
      url
      media_kind
    }
  }
}
    `;

export function useAllBenchmarksQuery(options?: Omit<Urql.UseQueryArgs<AllBenchmarksQueryVariables>, 'query'>) {
  return Urql.useQuery<AllBenchmarksQuery, AllBenchmarksQueryVariables>({ query: AllBenchmarksDocument, ...options });
};
export const AllFactoryGroupsDocument = gql`
    query AllFactoryGroups($name: String) {
  all_factory_groups(query: $name) {
    id
    name
    members {
      sequence
      factory {
        id
        name
      }
    }
    factories {
      id
      name
    }
  }
}
    `;

export function useAllFactoryGroupsQuery(options?: Omit<Urql.UseQueryArgs<AllFactoryGroupsQueryVariables>, 'query'>) {
  return Urql.useQuery<AllFactoryGroupsQuery, AllFactoryGroupsQueryVariables>({ query: AllFactoryGroupsDocument, ...options });
};
export const BenchmarkGraphQueryDocument = gql`
    query BenchmarkGraphQuery($filter: BenchmarksByFactoryGroupInput!) {
  benchmarks_by_factory_groups(filter: $filter) {
    factory_group {
      name
      id
    }
    benchmarks {
      factory {
        id
        name
      }
      id
      opening_rate
      latest_rate
      last_closing_rate
      opening_rate
      last_closing_rate_time
      opening_rate_time
      updated_at
      commodity_grade {
        id
        name
        commodity {
          id
          name
        }
      }
    }
  }
}
    `;

export function useBenchmarkGraphQueryQuery(options: Omit<Urql.UseQueryArgs<BenchmarkGraphQueryQueryVariables>, 'query'>) {
  return Urql.useQuery<BenchmarkGraphQueryQuery, BenchmarkGraphQueryQueryVariables>({ query: BenchmarkGraphQueryDocument, ...options });
};
export const UpsertFactoryGroupDocument = gql`
    mutation UpsertFactoryGroup($input: UpsertFactoryInput!) {
  upsert_factory_group(inputs: $input) {
    factory_group {
      id
      name
      factories {
        id
      }
    }
  }
}
    `;

export function useUpsertFactoryGroupMutation() {
  return Urql.useMutation<UpsertFactoryGroupMutation, UpsertFactoryGroupMutationVariables>(UpsertFactoryGroupDocument);
};
export const AddUpdateBenchmarkDocument = gql`
    mutation AddUpdateBenchmark($inputs: UpsertBenchmarkInput!) {
  upsert_benchmark(inputs: $inputs) {
    benchmark {
      id
      not_buying
      from_rate
      to_rate
      recorded_at
      remark
      no_of_days
      commodity_grade {
        id
        name
        commodity {
          id
          name
        }
      }
      factory {
        id
        name
      }
      summary {
        id
        opening_rate
        latest_rate
        last_closing_rate
        last_closing_rate_time
        opening_rate_time
        latest_rate_time
        pin_index
        date
        updated_at
      }
      recorded_by {
        id
        username
      }
      rate_unit {
        id
        name
      }
      attachments {
        id
        media_kind
        url
      }
    }
  }
}
    `;

export function useAddUpdateBenchmarkMutation() {
  return Urql.useMutation<AddUpdateBenchmarkMutation, AddUpdateBenchmarkMutationVariables>(AddUpdateBenchmarkDocument);
};
export const PinIndexDocument = gql`
    mutation PinIndex($summary_id: ID!) {
  pin_summary(summary_id: $summary_id) {
    summary {
      id
      pin_index
    }
  }
}
    `;

export function usePinIndexMutation() {
  return Urql.useMutation<PinIndexMutation, PinIndexMutationVariables>(PinIndexDocument);
};
export const BenchmarkDetailDocument = gql`
    query BenchmarkDetail($id: ID!) {
  benchmark_detail(id: $id) {
    id
    recorded_at
    from_rate
    not_buying
    to_rate
    commodity_grade {
      id
      name
      specifications
      commodity {
        id
        name
      }
    }
    factory {
      id
      name
    }
    no_of_days
    remark
    rate_unit {
      id
      name
    }
    attachments {
      id
      url
      media_kind
    }
  }
}
    `;

export function useBenchmarkDetailQuery(options: Omit<Urql.UseQueryArgs<BenchmarkDetailQueryVariables>, 'query'>) {
  return Urql.useQuery<BenchmarkDetailQuery, BenchmarkDetailQueryVariables>({ query: BenchmarkDetailDocument, ...options });
};
export const FactoryGroupDetailsDocument = gql`
    query FactoryGroupDetails($id: ID!) {
  factory_group_detail(id: $id) {
    id
    name
    members {
      id
      sequence
      factory {
        id
        name
      }
    }
  }
}
    `;

export function useFactoryGroupDetailsQuery(options: Omit<Urql.UseQueryArgs<FactoryGroupDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<FactoryGroupDetailsQuery, FactoryGroupDetailsQueryVariables>({ query: FactoryGroupDetailsDocument, ...options });
};
export const AddUpdateFactoryDocument = gql`
    mutation AddUpdateFactory($inputs: UpsertFactoryInput!) {
  upsert_factory(inputs: $inputs) {
    factory {
      id
    }
  }
}
    `;

export function useAddUpdateFactoryMutation() {
  return Urql.useMutation<AddUpdateFactoryMutation, AddUpdateFactoryMutationVariables>(AddUpdateFactoryDocument);
};
export const FactoryDetailsDocument = gql`
    query FactoryDetails($id: ID!) {
  factory_detail(id: $id) {
    id
    name
  }
}
    `;

export function useFactoryDetailsQuery(options: Omit<Urql.UseQueryArgs<FactoryDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<FactoryDetailsQuery, FactoryDetailsQueryVariables>({ query: FactoryDetailsDocument, ...options });
};
export const BenchmarksByFactoryDocument = gql`
    query BenchmarksByFactory($query: String, $filter: BenchmarksByFactoryInput, $first: Int, $skip: Int) {
  benchmarks_by_factory(
    query: $query
    filter: $filter
    first: $first
    skip: $skip
  ) {
    pin_index
    factory {
      id
      name
    }
    opening_summary {
      id
      opening_rate
      opening_rate_time
      opening_rate_unit {
        id
        name
      }
    }
    latest_summary {
      id
      latest_rate
      latest_rate_time
      latest_rate_unit {
        id
        name
      }
    }
    closing_summary {
      id
      latest_rate
      latest_rate_time
      latest_rate_unit {
        id
        name
      }
    }
  }
}
    `;

export function useBenchmarksByFactoryQuery(options?: Omit<Urql.UseQueryArgs<BenchmarksByFactoryQueryVariables>, 'query'>) {
  return Urql.useQuery<BenchmarksByFactoryQuery, BenchmarksByFactoryQueryVariables>({ query: BenchmarksByFactoryDocument, ...options });
};
export const PinFactoryDocument = gql`
    mutation PinFactory($factory_id: ID!) {
  pin_factory(factory_id: $factory_id) {
    factory {
      id
    }
  }
}
    `;

export function usePinFactoryMutation() {
  return Urql.useMutation<PinFactoryMutation, PinFactoryMutationVariables>(PinFactoryDocument);
};
export const BenchmarksByFactoryCountDocument = gql`
    query BenchmarksByFactoryCount($query: String, $filter: BenchmarksByFactoryInput) {
  benchmarks_by_factory_count(query: $query, filter: $filter)
}
    `;

export function useBenchmarksByFactoryCountQuery(options?: Omit<Urql.UseQueryArgs<BenchmarksByFactoryCountQueryVariables>, 'query'>) {
  return Urql.useQuery<BenchmarksByFactoryCountQuery, BenchmarksByFactoryCountQueryVariables>({ query: BenchmarksByFactoryCountDocument, ...options });
};
export const AllBaseUsersDocument = gql`
    query allBaseUsers($query: String, $first: Int, $skip: Int) {
  all_base_users(query: $query, first: $first, skip: $skip) {
    id
    last_login
    username
    first_name
    last_name
    email
    permissions {
      id
      name
      codename
    }
    is_dashboard_admin
    photo {
      id
      url
    }
    created_by {
      id
      username
    }
    date_joined
    updated_by {
      id
      username
    }
    created_at
  }
}
    `;

export function useAllBaseUsersQuery(options?: Omit<Urql.UseQueryArgs<AllBaseUsersQueryVariables>, 'query'>) {
  return Urql.useQuery<AllBaseUsersQuery, AllBaseUsersQueryVariables>({ query: AllBaseUsersDocument, ...options });
};
export const AllBaseUsersCountDocument = gql`
    query allBaseUsersCount($query: String, $first: Int, $skip: Int) {
  all_base_users(query: $query, first: $first, skip: $skip) {
    id
  }
}
    `;

export function useAllBaseUsersCountQuery(options?: Omit<Urql.UseQueryArgs<AllBaseUsersCountQueryVariables>, 'query'>) {
  return Urql.useQuery<AllBaseUsersCountQuery, AllBaseUsersCountQueryVariables>({ query: AllBaseUsersCountDocument, ...options });
};
export const AddUpdateBaseUserDocument = gql`
    mutation addUpdateBaseUser($input: UpsertBaseUserInput!) {
  upsert_base_user(inputs: $input) {
    user {
      id
      is_dashboard_admin
      first_name
      last_name
      permissions {
        id
        name
        codename
      }
      photo {
        id
        url
        media_kind
      }
    }
  }
}
    `;

export function useAddUpdateBaseUserMutation() {
  return Urql.useMutation<AddUpdateBaseUserMutation, AddUpdateBaseUserMutationVariables>(AddUpdateBaseUserDocument);
};
export const AllPermissionsDocument = gql`
    query allPermissions {
  all_permissions {
    id
    name
    codename
  }
}
    `;

export function useAllPermissionsQuery(options?: Omit<Urql.UseQueryArgs<AllPermissionsQueryVariables>, 'query'>) {
  return Urql.useQuery<AllPermissionsQuery, AllPermissionsQueryVariables>({ query: AllPermissionsDocument, ...options });
};
export const BaseUserDetailsDocument = gql`
    query BaseUserDetails($id: ID!) {
  base_user_detail(id: $id) {
    id
    first_name
    last_name
    username
    email
    is_dashboard_admin
    permissions {
      id
      name
      codename
    }
    photo {
      id
      url
    }
  }
}
    `;

export function useBaseUserDetailsQuery(options: Omit<Urql.UseQueryArgs<BaseUserDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<BaseUserDetailsQuery, BaseUserDetailsQueryVariables>({ query: BaseUserDetailsDocument, ...options });
};