import { Activity, User } from "@phosphor-icons/react"
import { MenuItem } from "@vesatogo/grass-core"
import { NavLink, Outlet } from "react-router-dom"
import { Permission } from "~/constants/Permissions"
import Benchmark from "~/pages/d/benchmark"
import BenchmarkDetails from "~/pages/d/benchmark/[id]"
import Factories from "~/pages/d/benchmark/factories/[id]"
import BenchmarkGraph from "~/pages/d/benchmark/graph"
import Groups from "~/pages/d/benchmark/groups/[id]"
import UserManagement from "~/pages/d/user-management"
import UserDetails from "~/pages/d/user-management/[id]"

export function SidebarMenuItem({
  link,
  name,
}: {
  link: string
  name: string
}) {
  return (
    <MenuItem className="p-0 [&>div]:m-0">
      <NavLink
        className={({ isActive }) => `p-2 block ${isActive && "bg-gray-200"}`}
        to={link}
      >
        {name}
      </NavLink>
    </MenuItem>
  )
}

export const dashboardRoutes: any[] = [
  {
    path: "benchmark",
    title: "Benchmark Data",
    icon: <Activity weight="fill" />,
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        index: true,
        element: <Benchmark />,
      },
      {
        path: "groups",
        element: <Groups />,
        children: [
          {
            path: ":id",
            element: <Groups />,
          },
        ],
      },
      {
        path: "factories",
        element: <Factories />,
        children: [
          {
            path: ":id",
            element: <Factories />,
          },
        ],
      },
      {
        path: "graph",
        element: <BenchmarkGraph />,
      },
      {
        path: ":id",
        permission: Permission.CanRecordBenchmark,
        element: <BenchmarkDetails />,
      },
    ],
  },
  {
    path: "user-management",
    title: "User Management",
    icon: <User weight="fill" />,
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        index: true,
        element: <UserManagement />,
      },
      {
        path: ":id",
        element: <UserDetails />,
      },
    ],
  },
]
