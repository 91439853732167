import { useForm } from "@mantine/form"
import {
  ArrowLeft,
  Calendar,
  Clock,
  CurrencyInr,
  Info,
} from "@phosphor-icons/react"
import {
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  FormGroup,
  FormInput,
  FormQuerySelector,
  InputGroup,
  ModuleContent,
  ModuleHeader,
  RadioGroup,
  SpinnerOverlay,
  TextArea,
  Tooltip,
} from "@vesatogo/grass-core"
import { FileUpload } from "@vesatogo/grass-dropzone"
import dayjs from "dayjs"
import { cloneDeep, get, set } from "lodash-es"
import { useEffect, useMemo, useState } from "react"
import { toast } from "react-hot-toast"
import { Link, useNavigate, useParams } from "react-router-dom"
import { DateSelector } from "~/components/DateSelector"
import {
  useAuthorization,
  useIsSuperAdmin,
} from "~/constants/AuthorizationProvider"
import { Environment } from "~/constants/Environment"
import { Permission } from "~/constants/Permissions"
import { AppRoutes } from "~/constants/routes"
import {
  useAddUpdateBenchmarkMutation,
  useAllCommoditiesQuery,
  useAllCommodityGradesQuery,
  useAllFactoriesQuery,
  useAllUnitsQuery,
  useBenchmarkDetailQuery,
} from "~/generated/graphql"

const INITIAL_STATE = {
  commodity: {
    id: null,
    name: "",
  },
  factory: {
    id: null,
    name: "",
    label: "" || undefined,
  },
  grade: {
    id: "",
    name: "",
    specifications: "",
  },
  rate: {
    to: null,
    from: null,
    unit: {
      id: undefined,
      name: "",
    },
    no_of_days: null,
  },
  recorded_at: null,
  attachments: [],
  remark: "",
  not_buying: false,
}

export type InitialState = typeof INITIAL_STATE
export const BenchmarkDetails = () => {
  const { id } = useParams()
  const [submitted, setSubmitted] = useState(false)

  const canRecordBenchmark = useAuthorization(Permission.CanRecordBenchmark)
  const navigate = useNavigate()
  const isSuperAdmin = useIsSuperAdmin()
  const isAuthorized = useMemo(
    () => (isSuperAdmin ? true : canRecordBenchmark ? true : false),
    [isSuperAdmin]
  )
  const form = useForm<InitialState>({
    initialValues: INITIAL_STATE,
  })
  const { values: state, setValues: setState } = form
  const [{ data, fetching: benchmarkDetailsFetching }, refetch] =
    useBenchmarkDetailQuery({
      variables: {
        id: id || "",
      },
      pause: !id || id === "new",
    })
  const [{ data: commodityData, fetching: commodityFetching }] =
    useAllCommoditiesQuery()

  const [{ data: gradeData, fetching: gradesFetching }] =
    useAllCommodityGradesQuery({
      variables: {
        commodity_id: state?.commodity?.id,
      },
      pause: !state?.commodity?.id,
    })

  const [{ data: unitData, fetching: unitsFetching }] = useAllUnitsQuery()

  const [{ fetching: upsertFetching }, upsertBenchmark] =
    useAddUpdateBenchmarkMutation()

  useEffect(() => {
    const benchmarkData = data?.benchmark_detail
    setState({
      commodity:
        (benchmarkData?.commodity_grade?.commodity as any) ||
        commodityData?.all_commoditys?.[0],
      factory: benchmarkData?.factory as any,
      grade:
        (benchmarkData?.commodity_grade as any) ||
        gradeData?.all_commodity_grades?.[0],
      rate: {
        to: benchmarkData?.to_rate || null,
        from: benchmarkData?.from_rate,
        unit: (benchmarkData?.rate_unit as any) || unitData?.all_units?.[0],
        no_of_days: benchmarkData?.no_of_days as any,
      },
      recorded_at: benchmarkData?.recorded_at || new Date(),
      attachments: benchmarkData?.attachments || ([] as any),
      remark: benchmarkData?.remark as any,
      not_buying: benchmarkData?.not_buying,
    })
  }, [id, data, commodityData, unitData])

  useEffect(() => {
    if (!state?.grade) {
      setState(val => ({
        ...val,
        grade: gradeData?.all_commodity_grades?.[0] as any,
      }))
    }
  }, [gradeData])

  function inputProps(name: string) {
    return {
      value: get(state, name, ""),
      onChange: e => {
        const stateCopy = cloneDeep(state)
        set(stateCopy, name, e?.target ? e.target.value : e)
        setState(stateCopy)
      },
    }
  }

  async function handleSubmit(onComplete?: () => void) {
    setSubmitted(true)
    if (
      !state?.commodity ||
      !state?.factory ||
      !state.grade ||
      !state?.recorded_at ||
      (!state?.not_buying && state?.rate?.to !== null && !state?.rate?.to) ||
      (!state?.not_buying && (!state?.rate?.from || !state?.rate?.unit))
    ) {
      return toast.error("Please enter mandatory details!")
    }

    const { data, error } = await upsertBenchmark({
      inputs: {
        attachments:
          state.attachments?.map((media: any) => {
            return { url: media?.url, media_kind: media?.media_kind }
          }) || [],
        commodity_grade_id: state?.grade?.id,
        factory_id: state?.factory?.id,
        from_rate: state?.not_buying ? null : Number(state?.rate?.from as any),
        to_rate: state?.not_buying
          ? null
          : state?.rate?.to
          ? Number(state?.rate?.to as any)
          : null,
        id: id !== "new" ? id : null,
        no_of_days: Number(state?.rate?.no_of_days as any),
        rate_unit_id: state?.rate?.unit?.id,
        recorded_at: state?.recorded_at,
        remark: state?.remark,
        not_buying: state?.not_buying,
      },
    })
    if (error) {
      return toast.error("Something went wrong!")
    }
    refetch({ requestPolicy: "network-only" })
    toast.success("Saved successfully")
    onComplete?.()
  }

  return (
    <>
      <ModuleHeader
        first={
          <div className="flex !items-end">
            <ArrowLeft
              className="w-6 mr-2 cursor-pointer "
              onClick={() => history.back()}
            />
            <Breadcrumbs
              items={[
                {
                  text: "Home",
                  link: `/d/benchmark?updatedAt=${Date.now()}`,
                  isActive: false,
                },
                {
                  text: id?.toString() || "New",
                  link: `/d/benchmark/${id}`,
                  isActive: true,
                },
              ]}
              linkRenderer={(link, item) => {
                return <Link to={link as any}>{item}</Link>
              }}
            />
          </div>
        }
      />
      <ModuleContent className="p-4">
        <SpinnerOverlay
          show={
            benchmarkDetailsFetching ||
            unitsFetching ||
            gradesFetching ||
            upsertFetching ||
            commodityFetching
          }
        ></SpinnerOverlay>
        <Card className="bg-white h-[80vh] py-4 container m-auto ">
          <div className="grid grid-cols-4 gap-8 px-10 p-10 ">
            <FormQuerySelector
              error={`${
                submitted && !state?.factory ? "Please select factory" : ""
              }`}
              getOptionLabel={item => {
                if (item.__isNew__) return item.label
                return item.name
              }}
              label="Factory"
              dataHook={useAllFactoriesQuery}
              required
              className={"w-full"}
              {...inputProps("factory")}
            />
            <FormQuerySelector
              label="Commodity"
              error={`${
                submitted && !state?.commodity ? "Please select commodity" : ""
              }`}
              dataHook={useAllCommoditiesQuery}
              required
              className={"w-full"}
              {...inputProps("commodity")}
              value={state?.commodity}
              onChange={e => {
                setState(val => ({
                  ...val,
                  commodity: e,
                  grade: {} as any,
                }))
              }}
            />

            <RadioGroup
              className="w-full gap-3"
              label="Grade"
              required
              value={state?.grade?.id as any}
              onChange={e => {
                const stateCopy = cloneDeep(state)
                set(
                  stateCopy,
                  "grade",
                  gradeData?.all_commodity_grades?.filter(item => {
                    return item?.id === e
                  })[0]
                )
                setState(stateCopy)
              }}
            >
              {gradeData?.all_commodity_grades?.map(item => {
                return (
                  <Checkbox
                    key={item?.id}
                    type="radio"
                    label={item?.name}
                    value={item?.id}
                  >
                    <Tooltip
                      content={
                        item?.specifications ? (
                          <div>
                            <div className="underline pb-2">Grade info</div>
                            {item?.specifications
                              ?.split("\n")
                              .map((item, idx) => {
                                return (
                                  <div key={idx}>{`${idx + 1}. ${item}`}</div>
                                )
                              })}
                          </div>
                        ) : (
                          "No info available!"
                        )
                      }
                    >
                      <Info size={15} className=" ml-2 inline-block"></Info>
                    </Tooltip>
                  </Checkbox>
                )
              })}
            </RadioGroup>
            {/* <FormGroup label=""> */}
            <Checkbox
              checked={state?.not_buying}
              onChange={e => setState({ ...state, not_buying: e })}
              className=""
            >
              Not Buying
            </Checkbox>
            {/* </FormGroup> */}
            <div className="w-full ">
              <FormGroup
                className={"flex"}
                label={
                  <div className="flex justify-between">
                    <div>
                      Rates <span className="text-red">*</span>
                    </div>
                    <div className="pb-1">
                      <Checkbox
                        disabled={state?.not_buying}
                        className="text-end"
                        checked={state.rate.to !== null}
                        onChange={e => {
                          const stateCopy = cloneDeep(state)
                          if (e) set(stateCopy, "rate.to", "")
                          else {
                            set(stateCopy, "rate.to", null)
                          }
                          setState(stateCopy)
                        }}
                        label={"Enable Price Range"}
                      ></Checkbox>
                    </div>
                  </div>
                }
              >
                <div className="flex gap-1">
                  <FormInput
                    disabled={state?.not_buying}
                    type="number"
                    placeholder="From"
                    error={`${
                      submitted && !state?.not_buying && !state?.rate?.from
                        ? "Please enter rate from"
                        : ""
                    }`}
                    inputProps={{
                      step: "0.1",
                    }}
                    leftElement={<CurrencyInr />}
                    {...inputProps("rate.from")}
                  ></FormInput>
                  <FormInput
                    type="number"
                    placeholder="To"
                    inputProps={{
                      step: "0.1",
                    }}
                    error={`${
                      submitted &&
                      !state?.not_buying &&
                      state?.rate?.to !== null &&
                      !state?.rate?.to
                        ? "Please enter rate to"
                        : ""
                    }`}
                    disabled={state?.not_buying || state?.rate?.to === null}
                    leftElement={<CurrencyInr />}
                    {...inputProps("rate.to")}
                  ></FormInput>
                </div>
              </FormGroup>
            </div>
            <FormInput
              type="number"
              // className={"self-end"}
              disabled={state?.not_buying || state?.rate?.to === null}
              {...inputProps("rate.no_of_days")}
              label="Transaction for number of days"
            ></FormInput>
            <FormQuerySelector
              disabled={state?.not_buying}
              label="Unit"
              error={`${
                submitted && !state?.rate?.unit ? "Please select unit" : ""
              }`}
              required
              className="w-full "
              dataHook={useAllUnitsQuery}
              {...inputProps("rate.unit")}
            ></FormQuerySelector>
            <div className="flex gap-1 items-end">
              <DateSelector
                showTimeInput
                dateFormat={"dd MMMM yyyy"}
                onChange={e => {
                  const stateCopy = cloneDeep(state)
                  set(stateCopy, "recorded_at", e?.target ? e.target.value : e)
                  setState(stateCopy)
                }}
                label="Time of recording"
                size="md"
                required
                selected={new Date(state?.recorded_at as any)}
                customInput={
                  <FormInput
                    size={"md"}
                    leftElement={<Calendar />}
                    label={"Time of recording"}
                  ></FormInput>
                }
              ></DateSelector>
              <InputGroup
                // label=""
                leftElement={<Clock></Clock>}
                inputProps={{
                  value: dayjs(state.recorded_at).format("hh:mm A"),
                }}
                // value={dayjs(state.recorded_at).format("hh:mm A")}
                // className={"self-center"}
                disabled
              ></InputGroup>
            </div>
            <div className="col-span-4">
              <TextArea
                label="Remark"
                {...inputProps("remark")}
                className="!w-full !border-gray-400"
              ></TextArea>
            </div>
            <div className="col-span-4">
              <FileUpload
                onError={toast.error}
                key={JSON.stringify(state)}
                uploadCardClassName="w-32  h-32 "
                className="w-full h-full max-h-fit flex flex-wrap"
                media={state.attachments || [{}]}
                endpoint={Environment.FileUploadHost}
                environment={""}
                appName="moepl/test"
                onChange={async media => {
                  const stateCopy = cloneDeep(state)
                  set(stateCopy, "attachments", media)
                  setState(stateCopy)
                  return
                }}
              />
            </div>
          </div>
          <div className="flex col-span-4 bg-gray-200 gap-4 justify-end px-8 py-2">
            <Button
              disabled={!isAuthorized}
              onClick={() => {
                handleSubmit(() => {
                  navigate(`${AppRoutes.benchmark}/new`)
                  refetch({ requestPolicy: "network-only" })
                })
              }}
              variant="ghost"
            >
              Save and add new
            </Button>
            <Button
              disabled={!isAuthorized}
              onClick={() => {
                handleSubmit(() => {
                  refetch({ requestPolicy: "network-only" })
                  navigate(`${AppRoutes.benchmark}?updatedAt=${Date.now()}`)
                })
              }}
            >
              Save
            </Button>
          </div>
        </Card>
      </ModuleContent>
    </>
  )
}

export default BenchmarkDetails
