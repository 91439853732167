import { Calendar } from "@phosphor-icons/react"
import { FormInput } from "@vesatogo/grass-core"
import { ReactNode } from "react"
import ReactDatePicker from "react-datepicker"

export type DateSelectorType = {
  selected: Date
  startDate?: Date
  endDate?: Date
  onChange: (dates) => void
  customInput?: ReactNode
  className?: string | undefined
  calendarClassName?: string | undefined
  clearButtonClassName?: string | undefined
  dateFormat?: string | string[] | undefined
  selectsRange?: boolean
  monthsShown?: number | undefined
  label?: string
  required?: boolean
  size?: "sm" | "md" | "lg"
  showTimeInput?: boolean
  disabled?: boolean
  readonly?: boolean
}

export const DateSelector = ({
  selected,
  startDate,
  endDate,
  onChange,
  className,
  calendarClassName,
  clearButtonClassName,
  dateFormat = "dd/MM/yyyy",
  selectsRange = false,
  monthsShown,
  label,
  size,
  required = false,
  showTimeInput = false,
  disabled = false,
  readonly = false,
  customInput = (
    <FormInput
      size={size}
      className={"!text-white"}
      inputGroupClassname={"bg-green-100 !text-green border-green"}
      leftElement={<Calendar />}
      label={label}
    ></FormInput>
  ),
}: DateSelectorType) => {
  return (
    <ReactDatePicker
      readOnly={readonly}
      disabled={disabled}
      showTimeInput={showTimeInput}
      required={required}
      dateFormat={dateFormat}
      selected={selected}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange={selectsRange}
      showMonthDropdown
      showYearDropdown
      className={"!text-green"}
      calendarClassName={calendarClassName}
      monthsShown={monthsShown}
      clearButtonClassName={clearButtonClassName}
      customInput={customInput}
    />
  )
}
