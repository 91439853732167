import { List, MagnifyingGlass, MapTrifold } from "@phosphor-icons/react"
import {
  FormInput,
  FormQuerySelector,
  ModuleContent,
  ModuleHeader,
  NoDataFound,
  PollingTimer,
  SegmentedControl,
} from "@vesatogo/grass-core"
import { CategoryScale, Chart, LinearScale, registerables } from "chart.js"
import zoomPlugin from "chartjs-plugin-zoom"
import dayjs from "dayjs"
import { cloneDeep, set } from "lodash-es"
import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AddBenchmark } from "~/components/AddBenchmark"
import { BenchmarkActions } from "~/components/BenchmarkActions"
import { DateSelector } from "~/components/DateSelector"
import { BenchmarkGraphView } from "~/components/benchmark/benchmark-graph/BenchmarkGraphView"
import {
  useAuthorization,
  useIsSuperAdmin,
} from "~/constants/AuthorizationProvider"
import { Permission } from "~/constants/Permissions"
import { AppRoutes } from "~/constants/routes"
import {
  useAllCommoditiesQuery,
  useAllCommodityGradesQuery,
  useAllFactoryGroupsQuery,
  useAllUnitsQuery,
  useBenchmarkGraphQueryQuery,
} from "~/generated/graphql"
Chart.register(LinearScale, CategoryScale, zoomPlugin, ...registerables)

export const BenchmarkGraph = () => {
  const navigate = useNavigate()
  const [state, setState] = useState<any>()
  const [priceType, setPriceType] = useState("latest")
  const [search, setSearch] = useState("")
  const canViewPastRecords = useAuthorization(Permission.CanViewPastRecords)
  const isSuperAdmin = useIsSuperAdmin()
  const canViewAnalyticsList = useAuthorization(
    Permission.CanViewAnalyticalGraph
  )
  const isAuthorized = useMemo(
    () => (isSuperAdmin ? true : canViewAnalyticsList ? true : false),
    [isSuperAdmin]
  )

  const [{ data: factoryGroups }] = useAllFactoryGroupsQuery()
  const [{ data: unitData }] = useAllUnitsQuery()

  const [{ data: allCommoditiesData }] = useAllCommoditiesQuery()

  const [commodity, setCommodity] = useState<any>()
  const [{ data: commodityGradesData }] = useAllCommodityGradesQuery({
    variables: {
      commodity_id: commodity?.id,
    },
    pause: !commodity?.id,
  })
  const [commodityGrade, setCommodityGrade] = useState<any>([])

  useEffect(() => {
    if (commodityGrade?.length === 0 && commodityGradesData) {
      const color = `hsl(${Math.floor(Math.random() * 220)}deg, 90%, 90%)`
      setCommodityGrade([
        { ...commodityGradesData?.all_commodity_grades?.[0], color: color },
      ])
    }
  }, [commodityGradesData])

  useEffect(() => {
    setCommodity(allCommoditiesData?.all_commoditys?.[0])
  }, [allCommoditiesData])

  const [{ data }, getGraphData] = useBenchmarkGraphQueryQuery({
    variables: {
      filter: {
        date: dayjs(state?.date).format("YYYY-MM-DD"),
        commodity_grade_id__in:
          (commodityGradesData?.all_commodity_grades?.map(grade => {
            return grade?.id
          }) as any) || [],
      },
    },
    pause:
      commodityGradesData?.all_commodity_grades?.length === 0 || !isAuthorized,
  })

  useEffect(() => {
    if (
      factoryGroups?.all_factory_groups?.length !== 0 &&
      !state?.factory_group_inputs &&
      unitData
    )
      setState({
        date: new Date(),
        factory_group_inputs: factoryGroups?.all_factory_groups?.map(item => {
          return {
            factory_group_id: item?.id,
            price_type: priceType,
          }
        }),
        unit_id: unitData?.all_units?.[0]?.id,
      })
  }, [factoryGroups, unitData])

  useEffect(() => {
    if (state?.length !== 0 && isAuthorized) {
      getGraphData()
    }
  }, [state])

  return (
    <>
      <ModuleHeader
        styleClassName="!z-10"
        first={
          <div className="flex gap-4 w-full">
            <SegmentedControl
              css={undefined}
              className={"w-full max-w-fit"}
              value={"graph"}
              onChange={value => {
                if (value === "list") {
                  navigate(`${AppRoutes.benchmark}?updatedAt=${Date.now()}`)
                }
              }}
              data={[
                {
                  label: "List",
                  value: "list",
                  icon: <List className="mr-1" />,
                },
                {
                  label: "Graph",
                  value: "graph",
                  icon: <MapTrifold className="mr-1" />,
                },
              ]}
            />
            <>
              <FormQuerySelector
                size="sm"
                className={"w-fit max-w-xs"}
                value={commodity}
                isClearable={false}
                onChange={e => {
                  setCommodity(e)
                }}
                dataHook={useAllCommoditiesQuery}
              />
            </>
          </div>
        }
        middle={
          <div className="w-full flex gap-2">
            <FormInput
              size="sm"
              leftElement={<MagnifyingGlass />}
              placeholder="Search by Group name"
              value={search}
              onChange={e => setSearch(e)}
              className={"w-full"}
            ></FormInput>
            <PollingTimer
              showProgress={false}
              onIntervalTrigger={() => {
                getGraphData({
                  requestPolicy: "network-only",
                })
              }}
              timeOptions={[
                {
                  interval: 15,
                  label: "15 Seconds",
                },
                {
                  interval: 30,
                  label: "30 Seconds",
                },
                {
                  interval: 60,
                  label: "1 Minute",
                },
                {
                  interval: 300,
                  label: "5 Minutes",
                },
                {
                  interval: 1500,
                  label: "15 Minutes",
                },
              ]}
            ></PollingTimer>
          </div>
        }
        last={
          <div className="flex gap-4 w-full justify-end">
            <BenchmarkActions />
            <div className="!max-w-xs">
              <DateSelector
                readonly={!isSuperAdmin && !canViewPastRecords}
                size="sm"
                onChange={e => {
                  const stateCopy = cloneDeep(state)
                  set(stateCopy, "date", e)
                  setState(val => ({
                    ...state,
                    date: stateCopy.date,
                  }))
                }}
                selected={state?.date}
              />
            </div>
            <AddBenchmark />
          </div>
        }
        className="w-benchmark-header"
      ></ModuleHeader>
      <ModuleContent className="relative">
        {isAuthorized ? (
          <BenchmarkGraphView
            data={data?.benchmarks_by_factory_groups}
            commodityGradesData={commodityGradesData}
            search={search}
          />
        ) : (
          <NoDataFound
            title="Oops! Looks like you don't have enough permissions."
            subtitle="Please check with your administrator."
            className="w-full h-[80vh]"
          />
        )}
      </ModuleContent>
    </>
  )
}

export default BenchmarkGraph
