import { List } from "@phosphor-icons/react"
import { Button, Menu, MenuItem, Popover } from "@vesatogo/grass-core"
import { useReportModal } from "@vesatogo/grass-reportmodal"
import dayjs from "dayjs"
import saveAs from "file-saver"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import {
  useAuthorization,
  useIsSuperAdmin,
} from "~/constants/AuthorizationProvider"
import { Environment } from "~/constants/Environment"
import { Permission } from "~/constants/Permissions"
import { AppRoutes } from "~/constants/routes"
import {
  useAllCommoditiesQuery,
  useAllCommodityGradesQuery,
  useAllFactoriesQuery,
  useAllFactoryGroupsQuery,
} from "~/generated/graphql"

export const BenchmarkActions = () => {
  const navigate = useNavigate()
  const onOpen = useReportModal(store => store.onOpen)
  const canCreateFactoryGroups = useAuthorization(
    Permission.CanCreateFactoryGroups
  )
  const canCreateFactories = useAuthorization(Permission.CanCreateFactory)
  const canDownloadReports = useAuthorization(Permission.CanDownloadReport)
  const isSuperAdmin = useIsSuperAdmin()
  const isAuthorizedToCreateFactoryGroups = useMemo(
    () => (isSuperAdmin ? true : canCreateFactoryGroups ? true : false),
    [isSuperAdmin]
  )
  const isAuthorizedToCreateFactories = useMemo(
    () => (isSuperAdmin ? true : canCreateFactories ? true : false),
    [isSuperAdmin]
  )
  const isAuthorizedToDownloadReports = useMemo(
    () => (isSuperAdmin ? true : canDownloadReports ? true : false),
    [isSuperAdmin]
  )
  return (
    <Popover
      trigger="click"
      interactive
      animation="fade"
      placement="bottom"
      minimal={true}
      content={
        <Menu className="max-h-52">
          <MenuItem
            disabled={!isAuthorizedToCreateFactoryGroups}
            onClick={() => navigate(AppRoutes.benchmarkGroups)}
          >
            Factory Groups
          </MenuItem>
          <MenuItem
            disabled={!isAuthorizedToCreateFactories}
            onClick={() => navigate(AppRoutes.benchmarkFactories)}
          >
            Register Factories
          </MenuItem>
          <MenuItem
            disabled={!isAuthorizedToDownloadReports}
            onClick={() => {
              onOpen(
                [
                  {
                    key: "recorded_at__date__gte",
                    label: "Recorded at From",
                    type: "date",
                  },
                  {
                    key: "recorded_at__date__lte",
                    label: "Recorded at To",
                    type: "date",
                  },
                  {
                    key: "factory__factory_groups__factory_group_id__in",
                    label: "Factory Groups",
                    type: "querySelect",
                    dataHook: useAllFactoryGroupsQuery,
                    multiple: true,
                  },
                  {
                    key: "factory_id__in",
                    label: "Factory",
                    type: "querySelect",
                    dataHook: useAllFactoriesQuery,
                    multiple: true,
                  },
                  {
                    key: "commodity_grade__commodity_id__in",
                    label: "Commodity",
                    type: "querySelect",
                    dataHook: useAllCommoditiesQuery,
                    multiple: true,
                  },
                  {
                    key: "commodity_grade_id__in",
                    label: "Commodity Grade",
                    type: "querySelect",
                    dataHook: useAllCommodityGradesQuery,
                    multiple: true,
                  },
                ],
                `${Environment.APIHost}/services/reports/benchmarking-report/`,
                {
                  hideEmailInput: true,
                  onResponseReceived: data => {
                    const blob = new Blob([data], {
                      type: "application/vnd.ms-excel",
                    })
                    saveAs(
                      blob,
                      `Benchmarking ${dayjs(new Date()).format(
                        "DD-MM-YYYY HH-mm-ss"
                      )}.xlsx`
                    )
                  },
                  primaryActionButtonText: "Download",
                  successMessage: "Report downloaded successfully!",
                },
                fieldValues => {
                  return {
                    method: "post",
                    auth: {
                      username: "rb-report-access",
                      password: Environment.ReportAccessPassword,
                    },
                    data: {
                      benchmark_filters: fieldValues,
                      timezone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                    },
                    responseType: "blob",
                  }
                }
              )
            }}
          >
            Download Reports in Excel
          </MenuItem>
        </Menu>
      }
    >
      <Button
        className="hover:bg-gray-300 rounded"
        variant="outline"
        size="sm"
        leftIcon={<List />}
      >
        Actions
      </Button>
    </Popover>
  )
}
