import { ConfirmModal } from "@vesatogo/grass-confirm-modal"
import "@vesatogo/grass-core/index.css"
import "@vesatogo/grass-dates/index.css"
import "@vesatogo/grass-dropzone/index.css"
import { ReportModal } from "@vesatogo/grass-reportmodal"
import "@vesatogo/grass-table/index.css"
import "react-datepicker/dist/react-datepicker.css"
import ReactDOM from "react-dom/client"
import { Toaster } from "react-hot-toast"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import "./index.css"
import ClientProvider from "./services/urql.service"

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <BrowserRouter>
    <ClientProvider>
      <Toaster
        toastOptions={{
          duration: 500,
          position: "top-right",
        }}
      />
      <ReportModal />
      <ConfirmModal />
      <App />
    </ClientProvider>
  </BrowserRouter>
  // </React.StrictMode>
)
