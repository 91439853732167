import { ArrowLeft, Plus } from "@phosphor-icons/react"
import {
  Breadcrumbs,
  Button,
  Card,
  FormInput,
  ModuleContent,
  ModuleHeader,
  NoDataFound,
  SpinnerOverlay,
} from "@vesatogo/grass-core"
import { useEffect, useMemo, useState } from "react"
import { toast } from "react-hot-toast"
import { Link, useNavigate, useParams } from "react-router-dom"
import { AllFactories } from "~/components/benchmark/factories/AllFactories"
import {
  useAuthorization,
  useIsSuperAdmin,
} from "~/constants/AuthorizationProvider"
import { Permission } from "~/constants/Permissions"
import { AppRoutes } from "~/constants/routes"
import {
  useAddUpdateFactoryMutation,
  useAllFactoriesQuery,
  useFactoryDetailsQuery,
} from "~/generated/graphql"

const INITIAL_STATE = {
  factory: null,
  checked: false,
}

export type InitialState = typeof INITIAL_STATE

export const Factories = () => {
  const [factoryName, setFactoryName] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [factorySearch, setFactorySearch] = useState("")
  const canCreateFactory = useAuthorization(Permission.CanCreateFactory)
  const isSuperAdmin = useIsSuperAdmin()
  const isAuthorized = useMemo(
    () => (isSuperAdmin ? true : canCreateFactory ? true : false),
    [isSuperAdmin]
  )

  const navigate = useNavigate()
  const [
    { data: factoryData, fetching: allFactoriesFetching },
    refetchFactories,
  ] = useAllFactoriesQuery({
    variables: {
      query: factorySearch,
    },
  })
  const { id } = useParams()

  const [{ data: factoryById, fetching }] = useFactoryDetailsQuery({
    variables: {
      id: id || "",
    },
    pause: !id || id === "new",
  })

  const [, upsertFactory] = useAddUpdateFactoryMutation()

  useEffect(() => {
    if (factoryById?.factory_detail) {
      setFactoryName(factoryById?.factory_detail?.name)
    }
  }, [factoryById])

  useEffect(() => {
    setFactoryName("")
  }, [id])

  const handleSubmit = async (onComplete?: any) => {
    setSubmitted(true)
    if (!factoryName) return
    const { data, error } = await upsertFactory({
      inputs: {
        id: id !== "new" ? id : null,
        name: factoryName,
      },
    })
    if (error) {
      toast.error("Something went wrong!")
      return
    }
    toast.success("Saved successfully!")
    refetchFactories({ requestPolicy: "network-only" })
    setFactoryName("")
    setSubmitted(false)
    onComplete?.()
  }
  return (
    <>
      <ModuleHeader
        first={
          <div className="flex !items-end">
            <ArrowLeft
              className="w-6 mr-2 cursor-pointer "
              onClick={() => history.back()}
            />
            <Breadcrumbs
              items={[
                {
                  text: "Home",
                  link: `/d/benchmark?updatedAt=${Date.now()}`,
                  isActive: false,
                },
                {
                  text:
                    id?.toString() === "new"
                      ? "Factory"
                      : factoryName || "Factory",
                  link: `/d/benchmark/groups`,
                  isActive: true,
                },
              ]}
              linkRenderer={(link, item) => {
                return <Link to={link as any}>{item}</Link>
              }}
            />
          </div>
        }
      />

      <ModuleContent className="p-4">
        <SpinnerOverlay
          show={fetching || allFactoriesFetching}
        ></SpinnerOverlay>
        <Card className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 bg-white h-[60vh] w-full max-w-6xl m-auto overflow-hidden flex">
          <Card className="w-[30%]  border-1  z-10 rounded-none p-4 flex flex-col gap-4 ">
            <AllFactories
              disabled={!isAuthorized}
              factorySearch={factorySearch}
              setFactorySearch={setFactorySearch}
              factoryData={factoryData?.all_factorys}
              id={id}
              onChange={() => {
                navigate(`${AppRoutes.benchmarkFactories}/new`)
              }}
            />
          </Card>

          <div className="flex flex-col justify-between h-[100%] w-[70%]">
            {id !== undefined ? (
              <>
                <div className={"space-y-4 w-full p-4 py-20"}>
                  <FormInput
                    label="Factory name"
                    required
                    error={
                      submitted && !factoryName
                        ? "Please enter a Factory name"
                        : ""
                    }
                    value={factoryName}
                    onChange={e => {
                      setFactoryName(e)
                    }}
                    placeholder="Enter info"
                    className={" max-w-lg mx-auto"}
                  ></FormInput>
                </div>
              </>
            ) : (
              <NoDataFound title="Please select a factory or create one here">
                <Button
                  disabled={!isAuthorized}
                  leftIcon={<Plus />}
                  variant="outline"
                  onClick={() =>
                    navigate(`${AppRoutes.benchmarkFactories}/new`)
                  }
                >
                  New Factory
                </Button>
              </NoDataFound>
            )}

            <div className=" h-[10%] bg-white border-t-2 rounded-b-lg flex justify-end gap-4 items-center px-4">
              <Button
                hidden={id === undefined}
                disabled={!isAuthorized}
                variant="ghost"
                onClick={() => {
                  handleSubmit(() => {
                    navigate(`${AppRoutes.benchmarkFactories}/new`)
                  })
                }}
              >
                Save & Add Another
              </Button>
              <Button
                hidden={id === undefined}
                disabled={!isAuthorized}
                onClick={() => {
                  handleSubmit(() => {
                    navigate(AppRoutes.benchmarkFactories)
                  })
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </Card>
      </ModuleContent>
    </>
  )
}

export default Factories
