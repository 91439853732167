import { AppShell, NavItem, UserProfileDrawer } from "@vesatogo/grass-core"
import classNames from "classnames"
import { useEffect, useMemo, useState } from "react"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"
import BizLogo from "~/assets/logo.svg"
import { AuthorizationProvider } from "~/constants/AuthorizationProvider"
import { Permission } from "~/constants/Permissions"
import { useMeQuery } from "~/generated/graphql"
import { dashboardRoutes } from "../routes/dashboard.routes"
import { useURQLClient } from "../services/urql.service"
import { Environment } from "~/constants/Environment"

const permissionsList = Object.values(Permission)

const DashboardLayout = ({ contentClassName = undefined }: any) => {
  const [open, setOpen] = useState(false)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [{ data }] = useMeQuery()
  const { resetClient } = useURQLClient()
  const me = data?.me
  const avatar = me?.photo?.url
  const name = me?.first_name
  const email = me?.email
  const username = me?.username
  const [{ data: auth }] = useMeQuery()
  const userPermissions =
    auth?.me?.permissions?.map(permission => {
      return permission?.codename
    }) || []

  const links: NavItem[] = useMemo(
    () =>
      dashboardRoutes
        .filter(route => {
          if (!route.permission) return true
          return userPermissions.includes(route.permission)
        })
        .map(route => {
          return {
            id: route.path,
            icon: route.icon,
            title: route.title,
            isActive: pathname.includes(route.path),
            renderLink: icon => <Link to={route.path}>{icon}</Link>,
          }
        }),
    [pathname, userPermissions]
  )

  useEffect(() => {
    if (pathname === "/d") {
      navigate("benchmark")
    }
  }, [pathname, navigate])

  useEffect(() => {
    if (!localStorage.getItem("token") && !data?.me?.id) {
      navigate("/")
    }
  }, [data])

  return (
    <AuthorizationProvider permissions={permissionsList}>
      <AppShell
        logoUrl={BizLogo}
        links={links}
        className="[&>nav]:overflow-visible [&>nav]:z-20"
        headerClassName="flex items-center p-4 z-20 justify-between overflow-visible"
        contentClassName="text-gray-700 bg-gray-200"
        header={
          <div className="flex items-center justify-between w-full">
            <span className="pl-1 text-gray-800 font-700 text-lg">
              {links.find(link => link.isActive)?.title}
            </span>
            <UserProfileDrawer
              isOpen={open}
              version={`${Environment.Name} - ${Environment.Version}`}
              setOpen={() => {
                setOpen(!open)
              }}
              user={{
                name: name || "",
                phone: email,
                avatar: avatar,
                uid: username,
              }}
              onLogout={() => {
                localStorage.removeItem("token")
                resetClient()
                navigate("/")
              }}
            ></UserProfileDrawer>
          </div>
        }
      >
        <main
          className={classNames(" text-gray-700 bg-gray-200", contentClassName)}
        >
          <Outlet />
        </main>
      </AppShell>
    </AuthorizationProvider>
  )
}

export default DashboardLayout
