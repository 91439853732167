import LoginPage from "~/pages"
import DashboardLayout from "../components/DashboardLayout"

import { dashboardRoutes } from "./dashboard.routes"

const routes: any[] = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "d",
    element: <DashboardLayout />,
    children: dashboardRoutes,
  },
]

export default routes
