import {
  CaretDown,
  CaretRight,
  List,
  MagnifyingGlass,
  MapTrifold,
  PushPin,
} from "@phosphor-icons/react"
import {
  Avatar,
  AvatarGroup,
  FormInput,
  FormQuerySelector,
  ModuleContent,
  ModuleHeader,
  NoDataFound,
  PollingTimer,
  SegmentedControl,
  Tag,
  Tooltip,
} from "@vesatogo/grass-core"
import { TableView } from "@vesatogo/grass-table"
import classNames from "classnames"
import dayjs from "dayjs"
import { useCallback, useEffect, useMemo, useState } from "react"
import { toast } from "react-hot-toast"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { AddBenchmark } from "~/components/AddBenchmark"
import { BenchmarkActions } from "~/components/BenchmarkActions"
import { DateSelector } from "~/components/DateSelector"
import {
  useAuthorization,
  useIsSuperAdmin,
} from "~/constants/AuthorizationProvider"
import { Permission } from "~/constants/Permissions"
import { AppRoutes } from "~/constants/routes"
import {
  useAllBenchmarksQuery,
  useAllCommoditiesQuery,
  useAllCommodityGradesQuery,
  useBenchmarksByFactoryCountQuery,
  useBenchmarksByFactoryQuery,
  usePinFactoryMutation,
} from "~/generated/graphql"

const INITIAL_STATE = {
  record_at_date: "",
  factory_id: [],
  commodity_grade: [],
  commodity: [],
  factory_group: [],
}
export type InitialState = typeof INITIAL_STATE

export const Benchmark = () => {
  const navigate = useNavigate()
  const [pins, setPins] = useState<any>()
  const [search, setSearch] = useState("")
  const [dateFrom, setDateFrom] = useState(new Date())
  const [dateTo, setDateTo] = useState(new Date())
  const [params, setParams] = useSearchParams()
  const updatedAt = params.get("updatedAt")
  const page = Number(params.get("page")) || 1
  const PAGE_SIZE = 24
  const canViewPastRecords = useAuthorization(Permission.CanViewPastRecords)
  const isSuperAdmin = useIsSuperAdmin()
  const canViewAnalyticsList = useAuthorization(
    Permission.CanViewAnalyticalList
  )
  const isAuthorized = useMemo(
    () => (isSuperAdmin ? true : canViewAnalyticsList ? true : false),
    [isSuperAdmin]
  )
  const [{ data: allCommoditiesData }] = useAllCommoditiesQuery()

  const [commodity, setCommodity] = useState<any>()
  const [{ data: commodityGradesData }] = useAllCommodityGradesQuery({
    variables: {
      commodity_id: commodity?.id,
    },
    pause: !commodity?.id,
  })
  const [commodityGrade, setCommodityGrade] = useState<any>([])

  useEffect(() => {
    if (commodityGrade?.id === undefined)
      setCommodityGrade(commodityGradesData?.all_commodity_grades?.[0])
  }, [commodityGradesData])

  useEffect(() => {
    setCommodity(allCommoditiesData?.all_commoditys?.[0])
  }, [allCommoditiesData])

  const [{ data }, refetch] = useBenchmarksByFactoryQuery({
    variables: {
      query: search,
      first: PAGE_SIZE,
      skip: (page - 1) * PAGE_SIZE,
      filter: {
        commodity_grade_id: commodityGrade?.id,
        date__gte: dayjs(dateFrom).format("YYYY-MM-DD"),
        date__lte: dayjs(dateTo).format("YYYY-MM-DD"),
      },
    },
    pause: !isAuthorized || !dateFrom || !dateTo,
  })

  const [{ data: benchmarkByFactoryCount }, refetchBenchmarkCount] =
    useBenchmarksByFactoryCountQuery({
      variables: {
        query: search,
        filter: {
          commodity_grade_id: commodityGrade?.id,
          date__gte: dayjs(dateFrom).format("YYYY-MM-DD"),
          date__lte: dayjs(dateTo).format("YYYY-MM-DD"),
        },
      },
      pause: !isAuthorized,
    })
  const totalBenchmarks =
    benchmarkByFactoryCount?.benchmarks_by_factory_count || 0

  const [, pinIndex] = usePinFactoryMutation()

  useEffect(() => {
    setPins(
      data?.benchmarks_by_factory?.filter(benchmark => {
        return benchmark?.pin_index !== null
      }).length
    )
  }, [data])

  useEffect(() => {
    if (updatedAt && isAuthorized) {
      refetch({ requestPolicy: "network-only" })
      refetchBenchmarkCount({ requestPolicy: "network-only" })
      params.delete("updatedAt")
      setParams(params)
    }
  }, [updatedAt])

  const COLUMNS = [
    {
      id: "expander",
      Header: "",
      Cell: ({ row }) =>
        row.depth === 0 ? (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}
          >
            {row.isExpanded ? (
              <CaretDown size={16} />
            ) : (
              <CaretRight size={16} />
            )}
          </span>
        ) : null,
    },

    {
      Header: "Factory",
      accessor: "factory",
      className: "!text-center",
      Cell: ({ row, value }) => {
        return (
          <div
            className="cursor-pointer"
            onClick={() => {
              row?.toggleRowExpanded()
            }}
          >
            {value.name}
          </div>
        )
      },
    },
    {
      Header: "Yesterday's Closing",
      accessor: "closing_summary",
      className: "!text-center",
      Cell: ({ row, value }) => {
        return value ? (
          <div
            className="cursor-pointer"
            onClick={() => {
              row?.toggleRowExpanded()
            }}
          >
            <Tag intent="info" variant="minimal">
              {value?.latest_rate !== null ? (
                <>
                  {"₹ "}
                  <span className="font-600">{`${Number(
                    value?.latest_rate
                  )}`}</span>
                  {` per ${value?.latest_rate_unit?.name}`}
                </>
              ) : (
                <>Not buying</>
              )}
            </Tag>
            <div>
              {dayjs(value?.latest_rate_time).format("D MMMM'YY | h:mm A")}
            </div>
          </div>
        ) : (
          "Not Recorded"
        )
      },
    },
    {
      Header: "Opening Price",
      accessor: "opening_summary",
      className: "!text-center",
      Cell: ({ row, value }) => {
        return value ? (
          <div
            className="cursor-pointer"
            onClick={() => {
              row?.toggleRowExpanded()
            }}
          >
            <Tag intent="info" variant="minimal">
              {value?.opening_rate !== null ? (
                <>
                  {"₹ "}
                  <span className="font-600">{`${Number(
                    value?.opening_rate
                  )}`}</span>
                  {` per ${value?.opening_rate_unit?.name}`}
                </>
              ) : (
                <>Not Buying</>
              )}
            </Tag>
            <div>
              {dayjs(value?.opening_rate_time).format("D MMMM'YY | h:mm A")}
            </div>
          </div>
        ) : (
          ""
        )
      },
    },
    {
      Header: "Latest Price",
      accessor: "latest_summary",
      className: "!text-center",
      Cell: ({ row, value }) => {
        return value ? (
          <div
            className="cursor-pointer"
            onClick={() => {
              row?.toggleRowExpanded()
            }}
          >
            <Tag intent="info" variant="minimal">
              {value?.latest_rate !== null ? (
                <>
                  {" "}
                  {"₹ "}
                  <span className="font-600">{`${Number(
                    value?.latest_rate
                  )}`}</span>
                  {` per ${value?.latest_rate_unit?.name}`}
                </>
              ) : (
                <>Not Buying</>
              )}
            </Tag>
            <div>
              {dayjs(value?.latest_rate_time).format("D MMMM'YY | h:mm A")}
            </div>
          </div>
        ) : (
          ""
        )
      },
    },
    {
      Header: "",
      accessor: "pin_index",
      Cell: ({ row: { original }, value }) => {
        return (
          <PushPin
            size={20}
            weight={value === null ? "regular" : "fill"}
            className={classNames(
              `${
                value !== null ? "text-blue-300" : "text-gray-500"
              } cursor-pointer !align-middle`
            )}
            onClick={async () => {
              const { data, error } = await pinIndex({
                factory_id: original?.factory?.id,
              })
              if (error) {
                toast.error("Something went wrong! Please try again later.")
                return
              } else {
                toast.success("Pinned successfully!")
                refetch({ requestPolicy: "network-only" })
              }
            }}
          ></PushPin>
        )
      },
    },
  ]

  const renderRowSubComponent = useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <SubRowAsync
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
      />
    ),
    []
  )

  const SubRowAsync = ({ row: myRow, rowProps, visibleColumns }) => {
    const SUB_COLUMNS = [
      {
        Header: "Time",
        accessor: "recorded_at",
        className:
          "!text-center w-[10%] border-r-1 border-b-1 border-gray-300 ",
        Cell: ({ row, value }) => {
          return (
            <Link className=" text-left" to={`/d/benchmark/${row.original.id}`}>
              {dayjs(value).format("DD/MM/YYYY h:mm A")}
            </Link>
          )
        },
      },
      {
        Header: "Price",
        accessor: "from_rate",
        className:
          "!text-center w-[20%] border-r-1 border-b-1 border-gray-300 ",
        Cell: ({ row, value }) => {
          return value !== null ? (
            <>{`₹${Number(value)} ${
              row.original?.to_rate !== null
                ? " - ₹" + Number(row?.original?.to_rate)
                : ""
            }  per ${row?.original?.rate_unit?.name}`}</>
          ) : (
            <>Not Buying</>
          )
        },
      },
      {
        Header: "Photos",
        accessor: "attachments",
        className:
          "!text-center w-[30%] border-r-1 border-b-1 border-gray-300 ",
        Cell: ({ row, value }) => {
          return (
            <AvatarGroup className="flex flex-wrap gap-4 ">
              {value?.map((item, idx) => {
                return (
                  <Avatar
                    size="lg"
                    className="!rounded-full"
                    src={item?.url}
                  ></Avatar>
                )
              })}
            </AvatarGroup>
          )
        },
      },
      {
        Header: "Remark",
        accessor: "remark",
        className:
          "!text-center w-[40%]  border-b-1 border-gray-300 overflow-hidden",
        Cell: ({ row, value }) => {
          return <>{value}</>
        },
      },
      {
        accessor: "recorded_by",
        className:
          "!text-center w-[40%] border-b-1  border-gray-300 overflow-hidden",
        Cell: ({ row, value }) => {
          return (
            <Tooltip
              content={
                value ? (
                  <div>
                    <p>
                      {`${value?.first_name + " " + value?.last_name}` || "na"}
                    </p>
                    {value?.last_login ? (
                      <p className="text-gray-400">
                        {dayjs(value?.last_login).format("DD MMM 'YY | h:mm a")}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <>Unkown User</>
                )
              }
            >
              <Avatar
                className="relative text-center"
                name={value?.first_name}
                src={value?.photo?.url}
              />
            </Tooltip>
          )
        },
      },
    ]
    const [{ data, fetching }, getData] = useAllBenchmarksQuery({
      variables: {
        filter: {
          factory_id: myRow?.original?.factory.id,
          commodity_grade_id: JSON.parse(
            localStorage?.getItem("commodity_grade") as any
          )?.id,
          recorded_at__gte: myRow?.original?.opening_summary?.opening_rate_time
            ? dayjs(myRow?.original?.opening_summary?.opening_rate_time).format(
                "YYYY-MM-DD"
              )
            : "",
          recorded_at__lte: myRow?.original?.latest_summary?.latest_rate_time
            ? dayjs(myRow?.original?.latest_summary?.latest_rate_time).format(
                "YYYY-MM-DD"
              )
            : "",
        },
      },
    })
    return (
      <td colSpan={6} className="p-2 px-10 bg-white">
        <TableView
          paginationProps={{ total: 1 }}
          columns={SUB_COLUMNS}
          data={data?.all_benchmarks || []}
          isLoading={fetching}
          className="content-center"
          getRowProps={row => {
            return {
              className: "text-center !align-middle !content-center",
            }
          }}
        ></TableView>
      </td>
    )
  }

  return (
    <>
      <ModuleHeader
        styleClassName="!z-10"
        first={
          <div className="flex gap-4 w-full">
            <SegmentedControl
              css={undefined}
              className={"w-full max-w-fit"}
              value={"list"}
              onChange={value => {
                if (value === "graph") {
                  navigate(AppRoutes.benchmarkGraph)
                }
              }}
              data={[
                {
                  label: "List",
                  value: "list",
                  icon: <List className="mr-1" />,
                },
                {
                  label: "Graph",
                  value: "graph",
                  icon: <MapTrifold className="mr-1" />,
                },
              ]}
            />
            <FormQuerySelector
              size="sm"
              className={"w-fit max-w-xs"}
              value={commodity}
              isClearable={false}
              onChange={e => {
                setCommodity(e)
              }}
              dataHook={useAllCommoditiesQuery}
            />
            <FormQuerySelector
              size="sm"
              className={"w-fit max-w-xs"}
              value={commodityGrade}
              isClearable={false}
              onChange={e => {
                setCommodityGrade(e)
              }}
              variables={{
                commodity_id: commodity?.id,
              }}
              dataHook={useAllCommodityGradesQuery}
            />
          </div>
        }
        middle={
          <div className="w-full flex gap-2">
            <FormInput
              size="sm"
              leftElement={<MagnifyingGlass />}
              placeholder="Search by Factory name"
              className={"w-full mx-auto "}
              value={search}
              onChange={e => {
                setSearch(e)
                refetch({ requestPolicy: "network-only" })
              }}
            ></FormInput>
            <PollingTimer
              className=""
              showProgress={false}
              onIntervalTrigger={() => {
                refetch({
                  requestPolicy: "network-only",
                })
              }}
              timeOptions={[
                {
                  interval: 15,
                  label: "15 Seconds",
                },
                {
                  interval: 30,
                  label: "30 Seconds",
                },
                {
                  interval: 60,
                  label: "1 Minute",
                },
                {
                  interval: 300,
                  label: "5 Minutes",
                },
                {
                  interval: 1500,
                  label: "15 Minutes",
                },
              ]}
            ></PollingTimer>
          </div>
        }
        last={
          <div className="flex gap-4 w-full justify-end">
            <BenchmarkActions />
            <div className="max-w-xs">
              <DateSelector
                readonly={!isSuperAdmin && !canViewPastRecords}
                dateFormat={"dd MMMM, yy"}
                selected={dateTo}
                onChange={dates => {
                  const [start, end] = dates
                  setDateFrom(start as any)
                  setDateTo(end as any)
                }}
                startDate={dateFrom}
                size="sm"
                endDate={dateTo}
                selectsRange
                className="!text-white"
                calendarClassName=""
                clearButtonClassName="after:bg-green-200 "
              />
            </div>
            <AddBenchmark />
          </div>
        }
        className="w-benchmark-header"
      ></ModuleHeader>
      <ModuleContent className="-z-10 grow h-content bg-gray-200 flex gap-2">
        {isAuthorized ? (
          <>
            <TableView
              className="w-full !h-full"
              tableClassName="!p-0 !mr-0 no-scrollbar !h-full bg-white"
              meta={<>{totalBenchmarks} Results</>}
              renderRowSubComponent={renderRowSubComponent}
              columns={COLUMNS}
              data={
                data?.benchmarks_by_factory?.slice(
                  0,
                  Math.ceil(PAGE_SIZE / 2)
                ) ?? []
              }
              getRowProps={row => {
                if (row?.original?.pin_index === pins) {
                  return {
                    className:
                      "border-b-8 border-solid border-gray-200 text-center !align-middle ",
                  }
                }
                return {
                  className: "text-center !align-middle ",
                }
              }}
            />
            <TableView
              className="w-full !h-full"
              paginationProps={{
                total: Math.ceil(totalBenchmarks / PAGE_SIZE),
                page: page,
                onChange(page) {
                  params.set("page", page.toString())
                  setParams(params)
                },
              }}
              tableClassName="!p-0 !ml-0 no-scrollbar !h-full bg-white"
              renderRowSubComponent={renderRowSubComponent}
              columns={COLUMNS}
              data={
                data?.benchmarks_by_factory?.slice(Math.ceil(PAGE_SIZE / 2)) ??
                []
              }
              getRowProps={row => {
                if (row?.original?.pin_index === pins) {
                  return {
                    className:
                      "border-b-8 border-solid border-gray-200 text-center !align-middle border-l-2",
                  }
                }
                return {
                  className: "text-center !align-middle",
                }
              }}
            />
          </>
        ) : (
          <NoDataFound
            title="Oops! Looks like you don't have enough permissions."
            subtitle="Please check with your administrator."
            className="w-full h-[80vh]"
          />
        )}
      </ModuleContent>
    </>
  )
}

export default Benchmark
