export const Permission = {
  CanRecordBenchmark: "rb_can_record_benchmark",
  CanViewAnalyticalGraph: "rb_can_view_analytical_graph",
  CanViewAnalyticalList: "rb_can_view_analytical_list",
  CanViewPastRecords: "rb_can_view_past_records",
  CanCreateFactoryGroups: "rb_can_create_factory_groups",
  CanCreateFactory: "rb_can_create_factory",
  CanCreateNewUser: "rb_can_create_new_user",
  CanLogInToDashboard: "rb_can_log_into_dashboard",
  CanChangePassword: "rb_can_change_password",
  CanDownloadReport: "rb_can_download_report",
  CanViewUserManagement: "rb_can_view_user_management",
} as const
