import { Card, MenuDivider } from "@vesatogo/grass-core"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Bar } from "react-chartjs-2"
import { Colors } from "~/constants/colors"

const STEP_SIZE = 100
export const BenchmarkGraphView = ({ data, search, commodityGradesData }) => {
  return (
    <div className="flex flex-col gap-4 p-4">
      {data
        ?.filter(group => {
          return group?.factory_group?.name
            ?.toLowerCase()
            ?.includes(search?.toLowerCase())
        })
        ?.map(graphData => {
          const groupedData = new Map()
          // Grouping data based on factory ids
          // note: groupBy from lodash destroys order of elements
          graphData?.benchmarks?.forEach(item => {
            const category = item?.factory?.id
            if (groupedData.has(category)) {
              groupedData.get(category).push(item)
            } else {
              groupedData.set(category, [item])
            }
          })
          const groupedMap = Array.from(groupedData.values())

          //setting the dataset array object for bar graph
          //Format:
          /**
           *
           * [
           *  {
           *    maxBarThickness: 50,
           *    label: grade1,
           *    factoryLabels: ["factory1", "factory2", ....],
           *    data: ["5100", "5409", null, "5436", ...]
           *  },
           * {
           *    maxBarThickness: 50,
           *    label: grade2,
           *    factoryLabels: ["factory1", "factory2", ....],
           *    data: ["5100", "5409", null, "5436", ...]
           *  },
           *  ...
           * ]
           *
           */
          const graph_mydata = commodityGradesData?.all_commodity_grades?.map(
            (grade, index) => {
              const filteredData = groupedMap?.map(item => {
                return item
                  ?.map(item2 => {
                    return {
                      ...item2,
                      factory_id: item2?.factory?.id,
                      factory_name: item2?.factory?.name,
                    }
                  })
                  ?.filter(item3 => {
                    return item3?.commodity_grade?.id === grade?.id
                  })
              })

              const latestRate = filteredData?.flatMap(item => {
                return item?.length === 0
                  ? undefined
                  : item?.map(item2 => {
                      return item2?.latest_rate === null
                        ? null
                        : item2?.latest_rate
                    })
              })

              const extractNumericValues = arr =>
                arr.map(item => parseFloat(item)).filter(value => !isNaN(value))

              const minimum = Math.min(...extractNumericValues(latestRate))
              return {
                maxBarThickness: 50,
                label: grade?.name,
                factoryLabels: filteredData?.flatMap((item: any) => {
                  return item?.map(item2 => {
                    return item2?.factory_name
                  })
                }),
                lastClosing: filteredData?.flatMap((item: any) => {
                  return item?.map(item2 => {
                    return item2?.last_closing_rate
                  })
                }),
                openingPrice: filteredData?.flatMap((item: any) => {
                  return item?.map(item2 => {
                    return item2?.opening_rate
                  })
                }),
                data: filteredData?.flatMap(item => {
                  return item?.length === 0
                    ? undefined
                    : item?.map(item2 => {
                        return item2?.latest_rate === null
                          ? minimum
                          : item2?.latest_rate
                      })
                }),
                isNA: filteredData?.flatMap(item => {
                  return item?.length === 0
                    ? null
                    : item?.map(item2 => {
                        return item2?.latest_rate === null ? true : false
                      })
                }),
                backgroundColor: Colors[index],
              }
            }
          )

          //extracting numeric values for min max calculations
          const extractNumericValues = arr =>
            arr.map(item => parseFloat(item)).filter(value => !isNaN(value))

          const numericValues = graph_mydata?.reduce((acc, item) => {
            const values = extractNumericValues(item.data)
            return acc.concat(values)
          }, [])

          // set the maximum and minimum value
          const maximum = Math.max(...(numericValues as any))
          const minimum = Math.min(...(numericValues as any))

          return (
            <Card className="p-4" key={graphData?.factory_group?.id}>
              <div className="p-4 w-full">{graphData?.factory_group?.name}</div>
              <MenuDivider className="bg-black  border-gray-500" />
              <div className="h-[50vh]">
                <Bar
                  plugins={[ChartDataLabels]}
                  data={{
                    labels: graph_mydata?.[0]?.factoryLabels,
                    datasets: graph_mydata || [],
                  }}
                  options={{
                    layout: {
                      padding: {
                        top: 30,
                        right: 20,
                        bottom: 20,
                        left: 20,
                      },
                    },
                    plugins: {
                      legend: {
                        labels: {
                          usePointStyle: true,
                          font: {
                            size: 12,
                            weight: "bold",
                          },
                        },
                      },
                      tooltip: {
                        enabled: true,
                        usePointStyle: true,
                        callbacks: {
                          label: (context: any) => {
                            const dataIndex = context?.dataIndex
                            const grade = context?.dataset?.label
                            const yesterdaysClosingPrice = `Yesterday's Closing:  ${
                              context?.dataset?.lastClosing[dataIndex] !== null
                                ? Number(
                                    context?.dataset?.lastClosing[dataIndex]
                                  )
                                : "-"
                            }`
                            const openingPrice = `Opening price:  ${
                              context?.dataset?.openingPrice[dataIndex] !== null
                                ? Number(
                                    context?.dataset?.openingPrice[dataIndex]
                                  )
                                : "-"
                            }`
                            const latestPrice = `Latest price:  ${
                              context?.dataset?.data[dataIndex] !== null
                                ? Number(context?.dataset?.data[dataIndex])
                                : "-"
                            }`
                            const isNA = context?.dataset?.isNA[dataIndex]
                            return isNA
                              ? [grade, "Not Buying"]
                              : [
                                  grade,
                                  yesterdaysClosingPrice,
                                  openingPrice,
                                  latestPrice,
                                ]
                          },
                        },
                      },
                      zoom: {
                        zoom: {
                          drag: {
                            enabled: true,
                            borderColor: "rgb(54, 162, 235)",
                            borderWidth: 1,
                            backgroundColor: "rgba(54, 162, 235, 0.3)",
                          },
                          wheel: {
                            modifierKey: "ctrl",
                            enabled: true,
                          },
                          mode: "x",
                          scaleMode: "x",
                        },
                        pan: {
                          modifierKey: "ctrl",
                          scaleMode: "x",
                          enabled: true,
                          mode: "x",
                        },
                        limits: {
                          x: {
                            minRange: 4,
                          },
                        },
                      },
                      datalabels: {
                        display: true,
                        // rotation: graphArray?.length > 20 ? -90 : 0,
                        // color: "rgba(90, 162, 235, 1)",
                        color(context: any) {
                          const dataIndex = context?.dataIndex
                          const isNA = context?.dataset?.isNA[dataIndex]
                          return isNA ? "red" : "gray"
                        },
                        anchor: "end",
                        offset: -20,
                        // offset: graphArray?.length > 20 ? -35 : -20,
                        font: {
                          weight: "bold",
                          size: 12,
                        },
                        formatter: (value, context: any) => {
                          const dataIndex = context?.dataIndex
                          const isNA = context?.dataset?.isNA[dataIndex]
                          return isNA ? "NB" : value
                        },
                        align: "start",
                        clip: true,
                      },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      // x: {
                      //   min: 0,
                      //   max: 5,
                      // },
                      y: {
                        max: Number.isNaN(maximum)
                          ? 0
                          : maximum + (STEP_SIZE - (maximum % STEP_SIZE)),
                        min: Number.isNaN(minimum)
                          ? 0
                          : minimum - (minimum % STEP_SIZE) - STEP_SIZE,
                        beginAtZero: true,
                        ticks: {
                          callback: function (label) {
                            return "₹ " + label
                          },
                          stepSize: STEP_SIZE,
                        },
                        title: {
                          display: true,
                          text: "per Quintal",
                          font: {
                            weight: "bold",
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </Card>
          )
        })}
    </div>
  )
}
