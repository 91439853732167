export const AppRoutes = {
  home: "/",
  benchmark: "/d/benchmark",
  benchmarkGraph: "/d/benchmark/graph",
  benchmarkGroups: "/d/benchmark/groups",
  benchmarkFactories: "/d/benchmark/factories",
  userManagement: "/d/user-management",
} as const

export const APIRoutes = {}
