import { useForm } from "@mantine/form"
import "@vesatogo/grass-carousel/index.css"
import { Modal, Toast } from "@vesatogo/grass-core"
import { GrassLogin } from "@vesatogo/grass-login"
import "@vesatogo/grass-login/index.css"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import BizLogo from "~/assets/logo.svg"
import { Permission } from "~/constants/Permissions"
import { useLoginMutation, useMeQuery } from "~/generated/graphql"

import { useURQLClient } from "~/services/urql.service"
const Logo = () => <img alt="biz logo" width={48} height={48} src={BizLogo} />
const LoginPage = () => {
  const navigate = useNavigate()
  const { resetClient } = useURQLClient()
  const { getInputProps, values } = useForm({
    initialValues: {
      username: "",
      password: "",
    },
  })
  const [{ data }] = useMeQuery()

  useEffect(() => {
    if (data?.me?.id) {
      const permissionLength = data?.me?.permissions?.filter(permission => {
        return (
          permission?.codename === Permission?.CanLogInToDashboard ||
          data?.me?.is_dashboard_admin
        )
      })?.length
      if (permissionLength !== undefined && permissionLength > 0) {
        navigate("/d/benchmark")
      } else {
        setIsOpen(true)
      }
    }
  }, [data])

  const [, login] = useLoginMutation()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <GrassLogin
        brand={{
          logo: Logo,
          displayName: "MOEL",
          legalName: "Maharashtra Oil Extractions Pvt. Ltd",
          phoneNumber: "",
          email: "",
          website: "https://moepl.in/",
        }}
        usernameProps={{
          inputProps: {
            ...getInputProps("username"),
            placeholder: "Employee ID",
          },
        }}
        passwordProps={{
          inputProps: getInputProps("password"),
        }}
        onSubmit={async () => {
          try {
            const { data, error } = await login(values)
            if (error || !data?.login) {
              return toast.custom(
                <Toast
                  title={"Unable to login, please enter valid credentials"}
                  intent="danger"
                />
              )
            }
            const auth = data?.login
            toast.custom(<Toast title={`Logged in as  ${auth.me?.username}`} />)
            localStorage.setItem("token", auth.token)
            resetClient()
          } catch (error) {
            console.error(error)
          }
        }}
      />

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        title="Login disabled"
        bodyClassName="max-w-lg"
        primaryActionButtonProps={{
          text: "Okay",
          intent: "danger",
          onClick: () => {
            localStorage.removeItem("token")
            resetClient()
            navigate("/")
            setIsOpen(false)
          },
        }}
      >
        <div className="text-center text-gray-700">
          You do not have permission to log into the dashboard. Please check
          with your Administrator!
        </div>
      </Modal>
    </>
  )
}

export default LoginPage
