import { TextAlignRight } from "@phosphor-icons/react"
import { Card } from "@vesatogo/grass-core"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"

const ColumnReorderGrid = ({ columns, onSequenceChange, data, disabled }) => {
  const handleDragEnd = result => {
    if (!result.destination) return // Return if the item was dropped outside of a droppable area

    const newColumns = Array.from(columns)
    const [reorderedColumn] = newColumns.splice(result.source.index, 1)
    newColumns.splice(result.destination.index, 0, reorderedColumn)

    onSequenceChange(newColumns)
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="columnGrid">
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {columns.map((column, index) => (
              <Draggable key={column} draggableId={column} index={index}>
                {provided => (
                  <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    className={`${
                      disabled ? "cursor-not-allowed pointer-events-none" : ""
                    } pb-4`}
                  >
                    <Card className="p-2 !rounded-md bg-green-100">
                      <TextAlignRight className="inline-block mr-2"></TextAlignRight>
                      {
                        data?.filter(factory => {
                          return factory.id === column
                        })[0]?.name
                      }
                    </Card>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default ColumnReorderGrid
