import { BookmarkSimple, MagnifyingGlass, Plus } from "@phosphor-icons/react"
import { Button, Card, FormInput, MenuDivider, Tag } from "@vesatogo/grass-core"
import classNames from "classnames"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "~/constants/routes"

export const GroupList = ({
  factoryGroupData,
  onChange,
  id,
  groupSearch,
  setGroupSearch,
  disabled,
}) => {
  const navigate = useNavigate()

  return (
    <>
      <div className="text-center">Groups</div>
      <MenuDivider></MenuDivider>
      <FormInput
        placeholder="Search by Group name"
        leftElement={<MagnifyingGlass />}
        value={groupSearch}
        onChange={e => {
          setGroupSearch(e)
        }}
      ></FormInput>
      <Button
        disabled={disabled || (id === "new" && id !== undefined)}
        className="w-full"
        size="lg"
        onClick={onChange}
        leftIcon={<Plus />}
      >
        Group
      </Button>

      <div className="flex flex-col gap-4 overflow-scroll py-4">
        {factoryGroupData?.map(factoryGroup => {
          return (
            <Card className="rounded-md" key={factoryGroup?.id}>
              <div
                className={classNames(
                  `${
                    id === factoryGroup?.id
                      ? "bg-blue-300 !text-gray-50"
                      : "bg-green-100 text-green-600"
                  }`,
                  " p-2 flex gap-2 items-center text-sm  cursor-pointer rounded-t-md"
                )}
                onClick={() => {
                  navigate(`${AppRoutes.benchmarkGroups}/${factoryGroup?.id}`)
                }}
                key={factoryGroup}
              >
                <BookmarkSimple />
                {factoryGroup?.name}
              </div>
              <div className="p-2 flex flex-wrap gap-2">
                {factoryGroup?.members?.map(member => {
                  return (
                    <Tag
                      intent="info"
                      variant="minimal"
                      key={member?.factory?.id}
                    >
                      {" "}
                      {member?.factory?.name}
                    </Tag>
                  )
                })}
              </div>
            </Card>
          )
        })}
      </div>
    </>
  )
}
