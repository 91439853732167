import {
  ArrowLeft,
  MagnifyingGlass,
  PencilSimple,
  Plus,
} from "@phosphor-icons/react"
import {
  Breadcrumbs,
  Button,
  Card,
  FormInput,
  MenuDivider,
  ModuleContent,
  ModuleHeader,
  NoDataFound,
} from "@vesatogo/grass-core"
import { useEffect, useMemo, useState } from "react"
import { toast } from "react-hot-toast"
import { Link, useNavigate, useParams } from "react-router-dom"
import ReorderableColumns from "~/components/ColumnReorder"
import { FactoryList } from "~/components/benchmark/groups/FactoryList"
import { GroupList } from "~/components/benchmark/groups/GroupList"
import {
  useAuthorization,
  useIsSuperAdmin,
} from "~/constants/AuthorizationProvider"
import { Permission } from "~/constants/Permissions"
import { AppRoutes } from "~/constants/routes"
import {
  useAllFactoriesQuery,
  useAllFactoryGroupsQuery,
  useFactoryGroupDetailsQuery,
  useUpsertFactoryGroupMutation,
} from "~/generated/graphql"

const INITIAL_STATE = {
  factory: null,
  checked: false,
}

export type InitialState = typeof INITIAL_STATE

export const Groups = () => {
  const [factorySearch, setFactorySearch] = useState("")
  const [state, setState] = useState<any>([])
  const [groupName, setGroupName] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [groupSearch, setGroupSearch] = useState("")
  const [editEnabled, setEditEnabled] = useState(false)
  const canCreateFactoryGroups = useAuthorization(
    Permission.CanCreateFactoryGroups
  )
  const isSuperAdmin = useIsSuperAdmin()
  const isAuthorized = useMemo(
    () => (isSuperAdmin ? true : canCreateFactoryGroups ? true : false),
    [isSuperAdmin]
  )

  const navigate = useNavigate()
  const [{ data: factoryGroupData }, refetchFactoryGroups] =
    useAllFactoryGroupsQuery({
      variables: {
        name: groupSearch,
      },
    })
  const { id } = useParams()

  const [{ data: factoryGroupById }] = useFactoryGroupDetailsQuery({
    variables: {
      id: id || "",
    },
    pause: !id || id === "new",
  })

  const [{ data }] = useAllFactoriesQuery()

  const [, upsertFactoryGroup] = useUpsertFactoryGroupMutation()

  useEffect(() => {
    if (factoryGroupById?.factory_group_detail) {
      setGroupName(factoryGroupById?.factory_group_detail?.name)
      setState(
        factoryGroupById?.factory_group_detail?.members?.map(
          member => member?.factory?.id
        )
      )
    }
  }, [factoryGroupById])

  useEffect(() => {
    setGroupName("")
    setState([])
    setSubmitted(false)
    if (id === "new") {
      setEditEnabled(true)
    } else {
      setEditEnabled(false)
    }
  }, [id])

  const handleSubmit = async (onComplete?: any) => {
    setSubmitted(true)
    if (!groupName) return
    const { data, error } = await upsertFactoryGroup({
      input: {
        id: id !== "new" ? id : null,
        name: groupName,
        factories: state.map((item, index) => {
          return {
            sequence: index,
            factory_id: item,
          }
        }),
      },
    })
    if (error) {
      toast.error("Something went wrong!")
      return
    }
    toast.success("Saved successfully!")
    refetchFactoryGroups({ requestPolicy: "network-only" })
    setState([])
    setGroupName("")
    setSubmitted(false)
    onComplete?.()
  }
  return (
    <>
      <ModuleHeader
        first={
          <div className="flex !items-end">
            <ArrowLeft
              className="w-6 mr-2 cursor-pointer "
              onClick={() => history.back()}
            />
            <Breadcrumbs
              items={[
                {
                  text: "Home",
                  link: `/d/benchmark?updatedAt=${Date.now()}`,
                  isActive: false,
                },
                {
                  text:
                    id?.toString() === "new"
                      ? "Factory Group"
                      : groupName || "Factory Group",
                  link: `/d/benchmark/groups`,
                  isActive: true,
                },
              ]}
              linkRenderer={(link, item) => {
                return <Link to={link as any}>{item}</Link>
              }}
            />
          </div>
        }
      />

      <ModuleContent className="p-4">
        <Card className="bg-white h-[80vh] overflow-hidden flex">
          <Card className="w-[20%]  border-1  z-10 rounded-none p-4 flex flex-col gap-4 ">
            <GroupList
              groupSearch={groupSearch}
              setGroupSearch={setGroupSearch}
              factoryGroupData={factoryGroupData?.all_factory_groups}
              id={id}
              disabled={!isAuthorized}
              onChange={() => {
                navigate(`${AppRoutes.benchmarkGroups}/new`)
              }}
            />
          </Card>

          <div className="flex flex-col h-[100%] w-[80%]">
            <div
              className={`h-[90%] cursor-not-allowed"
              } flex `}
            >
              {id !== undefined ? (
                <>
                  <div
                    className={`${
                      !isAuthorized || !editEnabled
                        ? "!cursor-not-allowed "
                        : ""
                    } space-y-4 w-full p-4 overflow-y-scroll`}
                  >
                    <FormInput
                      label="Factory group name"
                      required
                      error={
                        submitted && !groupName
                          ? "Please enter a Group name"
                          : ""
                      }
                      value={groupName}
                      onChange={e => {
                        setGroupName(e)
                      }}
                      disabled={!isAuthorized || !editEnabled}
                      placeholder="Enter info"
                      className={" max-w-lg mx-auto"}
                    ></FormInput>
                    <FormInput
                      value={factorySearch}
                      className={" max-w-lg mx-auto"}
                      onChange={e => {
                        setFactorySearch(e)
                      }}
                      placeholder="Search by Factory name"
                      leftElement={<MagnifyingGlass />}
                    ></FormInput>

                    <FactoryList
                      state={state}
                      disabled={!isAuthorized || !editEnabled}
                      setState={setState}
                      allFactories={data?.all_factorys?.filter(item => {
                        return item?.name
                          ?.toLowerCase()
                          ?.includes(factorySearch?.toLowerCase())
                      })}
                    ></FactoryList>
                  </div>
                </>
              ) : (
                <NoDataFound
                  className="w-full"
                  title="Please select a factory group or create one here"
                >
                  <Button
                    disabled={!isAuthorized}
                    leftIcon={<Plus />}
                    variant="outline"
                    onClick={() => navigate(`${AppRoutes.benchmarkGroups}/new`)}
                  >
                    New Factory Group
                  </Button>
                </NoDataFound>
              )}
              {state.length && id !== undefined ? (
                <Card className="w-[30%] max-w-xs border-1 p-4 !rounded-b-none overflow-y-scroll">
                  <header className="text-center p-4">
                    Customize sequence
                  </header>
                  <MenuDivider className="pb-2"></MenuDivider>
                  <div className="flex flex-col gap-4">
                    <ReorderableColumns
                      disabled={!isAuthorized || !editEnabled}
                      columns={state}
                      data={data?.all_factorys}
                      onSequenceChange={columns => setState(columns)}
                    />
                  </div>
                </Card>
              ) : null}
            </div>
            {state.length && id !== undefined ? (
              <div className=" h-[10%] bg-white border-t-2 rounded-b-lg flex justify-end gap-4 items-center px-4">
                <Button
                  hidden={!editEnabled}
                  className="max-w-sm w-1/5"
                  disabled={!isAuthorized}
                  variant="ghost"
                  onClick={() => {
                    handleSubmit(() => {
                      navigate(`${AppRoutes.benchmarkGroups}/new`)
                    })
                  }}
                >
                  Save & Add Another
                </Button>
                <Button
                  className="max-w-sm w-1/5"
                  hidden={!editEnabled}
                  disabled={!isAuthorized}
                  onClick={() => {
                    handleSubmit(() => {
                      navigate(AppRoutes.benchmarkGroups)
                    })
                  }}
                >
                  Save
                </Button>
                <Button
                  className="max-w-sm w-1/5"
                  hidden={editEnabled}
                  disabled={!isAuthorized}
                  intent="info"
                  leftIcon={<PencilSimple weight="fill" />}
                  variant="ghost"
                  onClick={() => {
                    setEditEnabled(!editEnabled)
                  }}
                >
                  Edit
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
        </Card>
      </ModuleContent>
    </>
  )
}

export default Groups
