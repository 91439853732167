import { MagnifyingGlass, Plus } from "@phosphor-icons/react"
import {
  Button,
  FormInput,
  ModuleContent,
  ModuleHeader,
  NoDataFound,
  Tag,
} from "@vesatogo/grass-core"
import { TableView } from "@vesatogo/grass-table"
import classNames from "classnames"
import dayjs from "dayjs"
import { useEffect, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
  useAuthorization,
  useIsSuperAdmin,
} from "~/constants/AuthorizationProvider"
import { Permission } from "~/constants/Permissions"
import { AppRoutes } from "~/constants/routes"
import {
  useAllBaseUsersCountQuery,
  useAllBaseUsersQuery,
} from "~/generated/graphql"

export const UserManagement = () => {
  const PAGE_SIZE = 20
  const navigate = useNavigate()
  const [search, setSearch] = useState("")
  const [params, setParams] = useSearchParams()
  const updatedAt = params.get("updatedAt")
  const page = Number(params.get("page")) || 1
  const isSuperAdmin = useIsSuperAdmin()
  const canViewUserManagement = useAuthorization(
    Permission.CanViewUserManagement
  )
  const canCreateNewUsers = useAuthorization(Permission.CanCreateNewUser)
  const isAuthorized = useMemo(
    () => (isSuperAdmin ? true : canViewUserManagement ? true : false),
    [isSuperAdmin]
  )
  const isAuthorizedCreateNewUsers = useMemo(
    () => (isSuperAdmin ? true : canCreateNewUsers ? true : false),
    [isSuperAdmin]
  )
  const [{ data, fetching }, refetch] = useAllBaseUsersQuery({
    variables: {
      query: search,
      first: PAGE_SIZE,
      skip: (page - 1) * PAGE_SIZE,
    },
    pause: !isAuthorized,
  })

  const [{ data: AllUsers }, refetchUserCount] = useAllBaseUsersCountQuery({
    pause: !isAuthorized,
  })
  const totalUsers = AllUsers?.all_base_users?.length || 0

  useEffect(() => {
    if (updatedAt && isAuthorized) {
      refetch({ requestPolicy: "network-only" })
      refetchUserCount({ requestPolicy: "network-only" })
      params.delete("updatedAt")
      setParams(params)
    }
  }, [updatedAt])

  const COLUMNS = [
    {
      Header: "Sr. no",
      accessor: "name",
      className: "!text-center w-[10%]",
      Cell: ({ row: { index } }) => {
        return <div className="cursor-pointer">{index + 1}</div>
      },
    },
    {
      Header: "User name",
      accessor: "username",
      className: "!text-center w-[10%]",
      Cell: ({ value }) => {
        return <div className="cursor-pointer">{value}</div>
      },
    },
    {
      Header: "Created On",
      accessor: "created_at",
      className: "!text-center w-[10%]",
      Cell: ({ value }) => {
        if (!value) return "-"
        return (
          <div className="cursor-pointer">
            {dayjs(value)?.format("D MMM 'YY")}
          </div>
        )
      },
    },
    {
      Header: "Created By",
      accessor: "created_by",
      className: "!text-center w-[10%]",
      Cell: ({ value }) => {
        if (!value) return "-"
        return <div className="cursor-pointer">{value?.username}</div>
      },
    },
    {
      Header: "Last Active",
      accessor: "last_login",
      className: "!text-center w-[10%]",
      Cell: ({ value }) => {
        if (!value) return "-"
        return (
          <div className="cursor-pointer flex flex-col">
            <span className="font-500">
              {dayjs(value)?.format("D MMM 'YY")}
            </span>
            <span className="">{dayjs(value)?.format("hh:mm")}</span>
          </div>
        )
      },
    },
    {
      Header: "Permissions",
      accessor: "permissions",
      className: "!text-center",
      Cell: ({ value }) => {
        if (value?.length === 0) return "-"
        return (
          <div className="flex gap-2 flex-wrap">
            {value?.map(item => {
              return (
                <Tag className="!rounded-xl" variant="minimal">
                  {item?.name}
                </Tag>
              )
            })}
          </div>
        )
      },
    },
  ]
  return (
    <>
      <ModuleHeader
        styleClassName="!z-10"
        first={<div className="flex gap-4 w-full"></div>}
        middle={
          <div className="w-full ">
            <FormInput
              size="sm"
              leftElement={<MagnifyingGlass />}
              placeholder="Search by User name"
              className={"w-full max-w-md mx-auto "}
              value={search}
              onChange={e => {
                setSearch(e)
              }}
            ></FormInput>
          </div>
        }
        last={
          <div className="flex gap-4 w-full justify-end">
            <Button
              disabled={!isAuthorizedCreateNewUsers}
              size="sm"
              leftIcon={<Plus />}
              onClick={() => navigate(`${AppRoutes.userManagement}/new`)}
            >
              User
            </Button>
          </div>
        }
        className="w-benchmark-header"
      ></ModuleHeader>
      <ModuleContent className="-z-10 grow h-content bg-gray-200 flex">
        {isAuthorized ? (
          <TableView
            className="w-full"
            paginationProps={{
              total: Math.ceil(totalUsers / PAGE_SIZE),
              page: page,
              onChange(page) {
                params.set("page", page.toString())
                setParams(params)
              },
            }}
            tableClassName="!p-0"
            isLoading={fetching}
            meta={<>{totalUsers} Results</>}
            columns={COLUMNS}
            data={data?.all_base_users || []}
            getCellProps={cell => {
              const permissions = cell?.row?.original?.permissions
              return {
                className: classNames(
                  cell?.column?.Header === "Permissions" &&
                    permissions?.length !== 0
                    ? ""
                    : "!text-center"
                ),
              }
            }}
            getRowProps={row => {
              return {
                onClick: () => {
                  if (isAuthorizedCreateNewUsers)
                    return navigate(
                      `${AppRoutes.userManagement}/${row?.original?.id}`
                    )
                },
              }
            }}
          />
        ) : (
          <NoDataFound
            title="Oops! Looks like you don't have enough permissions."
            subtitle="Please check with your administrator."
            className="w-full h-[80vh]"
          />
        )}
      </ModuleContent>
    </>
  )
}

export default UserManagement
