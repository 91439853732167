import { Card, Checkbox, Tag } from "@vesatogo/grass-core"

export const FactoryList = ({ state, allFactories, setState, disabled }) => {
  return (
    <>
      <div className="text-center text-blue-400 text-md">{`${state?.length} / ${allFactories?.length} Factory places selected`}</div>
      <div className="grid grid-cols-3 gap-8 w-full">
        {allFactories?.map(factory => {
          return (
            <Card
              key={factory?.id}
              className={`${
                state?.includes(factory?.id) ? "bg-green-100" : ""
              } p-2  flex justify-between !rounded-md`}
            >
              <Tag
                disabled={disabled}
                intent={disabled ? "none" : "primary"}
                className={`${
                  state?.includes(factory?.id) ? "" : "!bg-gray-300 !text-black"
                } !rounded-md mr-20 `}
              >
                {factory?.name}
              </Tag>
              <Checkbox
                disabled={disabled}
                checked={state?.includes(factory?.id)}
                onChange={e => {
                  if (e) {
                    setState(val => [...val, factory?.id])
                  } else {
                    setState(val => [
                      ...val?.filter(e => {
                        return e !== factory?.id
                      }),
                    ])
                  }
                }}
              ></Checkbox>
            </Card>
          )
        })}
      </div>
    </>
  )
}
