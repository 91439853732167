import { BookmarkSimple, MagnifyingGlass, Plus } from "@phosphor-icons/react"
import { Button, FormInput, MenuDivider } from "@vesatogo/grass-core"
import classNames from "classnames"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "~/constants/routes"

export const AllFactories = ({
  factoryData,
  onChange,
  id,
  factorySearch,
  setFactorySearch,
  disabled,
}) => {
  const navigate = useNavigate()

  return (
    <>
      <div className="text-center">Factories</div>
      <MenuDivider></MenuDivider>
      <FormInput
        placeholder="Search by factory name"
        leftElement={<MagnifyingGlass />}
        value={factorySearch}
        onChange={e => {
          setFactorySearch(e)
        }}
      ></FormInput>
      <Button
        disabled={disabled || (id === "new" && id !== undefined)}
        className="w-full"
        size="lg"
        onClick={onChange}
        leftIcon={<Plus />}
      >
        Factory
      </Button>

      <div className="flex flex-col gap-4 overflow-y-scroll py-4">
        {factoryData?.map(factory => {
          return (
            <div
              className={classNames(
                `${
                  id === factory?.id
                    ? "bg-blue-300 !text-gray-50"
                    : "bg-green-100 text-green-600"
                }`,
                " p-2 flex gap-2 items-center text-sm cursor-pointer rounded-md"
              )}
              onClick={() => {
                navigate(`${AppRoutes.benchmarkFactories}/${factory?.id}`)
              }}
              key={factory?.id}
            >
              <BookmarkSimple />
              {factory?.name}
            </div>
          )
        })}
      </div>
    </>
  )
}
